import { Box, Button, TextField, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";
import { CardContainer, SubContainer, SubTitle, Divider, Container } from "./styles";
import Translator from "../i18nComponents/Translator";
import FormControl from "@mui/material/FormControl";

export default function TraderCard() {
  const { handleSaveOptions } = useUser();

  const [emailIq, setEmailIq] = useState(null);
  const [passIq, setPassIq] = useState(null);
  const [tokenDemo, setTokenDemo] = useState(null);
  const [tokenReal, settokenReal] = useState(null);
  const [emailCapital, setEmailCapital] = useState(null);
  const [passCapital, setPassCapital] = useState(null);
  //
  const [emailQuotex, setEmailQuotex] = useState(null);
  const [passQuotex, setPassQuotex] = useState(null);
  //
  const [emailProcket, setEmailProcket] = useState(null);
  const [passProcket, setPassProcket] = useState(null);
  const [emailAvalon, setEmailAvalon] = useState(null);
  const [passAvalon, setPassAvalon] = useState(null);
  const [emailExnova, setEmailExnova] = useState(null);
  const [passExnova, setPassExnova] = useState(null);
  const [emailBinomo, setEmailBinomo] = useState(null);
  const [passBinomo, setPassBinomo] = useState(null);
  const [emailBitness, setEmailBitness] = useState(null);
  const [passBitness, setPassBitness] = useState(null);
  const [emailBinolla, setEmailBinolla] = useState(null);
  const [passBinolla, setPassBinolla] = useState(null);
  const [emailOlimp, setEmailOlimp] = useState(null);
  const [passOlimp, setPassOlimp] = useState(null);
  const [emailDayprofit, setEmailDayprofit] = useState(null);
  const [passDayprofit, setPassDayprofit] = useState(null);
  const [emailStockity, setEmailStockity] = useState(null);
  const [passStockity, setPassStockity] = useState(null);
  //
  const [emailBullex, setEmailBullex] = useState(null);
  const [passBullex, setPassBullex] = useState(null);
  //
  const [emailOptinary, setEmailOptinary] = useState(null);
  const [passOptinary, setPassOptinary] = useState(null);
  //
  const [emailBroker10, setEmailBroker10] = useState(null);
  const [passBroker10, setPassBroker10] = useState(null);
  //
  const [emailPixtrade, setEmailPixtrade] = useState(null);
  const [passPixtrade, setPassPixtrade] = useState(null);
  //
  const [emailOptgo, setEmailOptgo] = useState(null);
  const [passOptgo, setPassOptgo] = useState(null);
  //
  const [emailPolarium, setEmailPolarium] = useState(null);
  const [passPolarium, setPassPolarium] = useState(null);
  //
  const [emailHomeBroker, setEmailHomeBroker] = useState(null);
  const [passHomeBroker, setPassHomeBroker] = useState(null);
  //
  const [emailQuotexGoo, setEmailQuotexGoo] = useState(null);
  const [passQuotexGoo, setPassQuotexGoo] = useState(null);
  //
  const [emailBinollaGoo, setEmailBinollaGoo] = useState(null);
  const [passBinollaGoo, setPassBinollaGoo] = useState(null);
  //
  const [emailIQGoo, setEmailIQGoo] = useState(null);
  const [passIQGoo, setPassIQGoo] = useState(null);

  //const [quotexToken, setQuotexToken] = useState(null);
  //const [pocketToken, setPocketToken] = useState(null);
  //
  const [binomoToken, setBinomoToken] = useState(null);
  const [binomoDevice, setBinomoDevice] = useState(null);
  const [stockityToken, setStockityToken] = useState(null);
  const [stockityDevice, setStockityDevice] = useState(null);
  //

  const {t} = useTranslation()

  return (
    <SubContainer>

      <CardContainer>
        <SubTitle>IQ option</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailIq(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={t("senha")}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setPassIq(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailIq,
              password: passIq,
              key: "iqdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Quotex</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailQuotex(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassQuotex(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailQuotex,
              password: passQuotex,
              key: "quotexdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Pocket Option</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailProcket(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassProcket(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailProcket,
              password: passProcket,
              key: "pocketdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Exnova</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailExnova(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassExnova(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailExnova,
              password: passExnova,
              key: "exnovadata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Deriv</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Token Demo"}
            type="text"
            onChange={(e) => setTokenDemo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            label={"Token Real"}
            onChange={(e) => settokenReal(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              tokenDemo,
              tokenReal,
              key: "binarydata",
            })
          }
          variant="contained"
        >
         <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Binolla</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailBinolla(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassBinolla(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBinolla,
              password: passBinolla,
              key: "binolladata",
            })
          }
          variant="contained"
        >
         <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Binomo</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailBinomo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassBinomo(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBinomo,
              password: passBinomo,
              key: "binomodata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Stockity</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailStockity(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassStockity(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailStockity,
              password: passStockity,
              key: "stockitydata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Bullex</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailBullex(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassBullex(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBullex,
              password: passBullex,
              key: "bullexdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Capital Bear</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailCapital(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassCapital(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailCapital,
              password: passCapital,
              key: "capitaldata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>DayProfit</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailDayprofit(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassDayprofit(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailDayprofit,
              password: passDayprofit,
              key: "dayprofitdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Optinary</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailOptinary(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassOptinary(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailOptinary,
              password: passOptinary,
              key: "optinarydata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Broker10</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailBroker10(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassBroker10(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBroker10,
              password: passBroker10,
              key: "broker10data",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Pixtrade</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailPixtrade(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassPixtrade(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailPixtrade,
              password: passPixtrade,
              key: "pixtradedata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Optgo</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailOptgo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassOptgo(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailOptgo,
              password: passOptgo,
              key: "optgodata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Polarium</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailPolarium(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassPolarium(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailPolarium,
              password: passPolarium,
              key: "polariumdata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>HomeBroker</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailHomeBroker(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassHomeBroker(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailHomeBroker,
              password: passHomeBroker,
              key: "homebroker",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>OlympTrade</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailOlimp(e.target.value)}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassOlimp(e.target.value)}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailOlimp,
              password: passOlimp,
              key: "olympdata",
            })
          }
          variant="contained"
        >
         <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Avalon</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailAvalon(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassAvalon(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailAvalon,
              password: passAvalon,
              key: "avalondata",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

    </SubContainer>

  );
}

/*

<CardContainer>
        <SubTitle>Binomo Token</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Token"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setBinomoToken(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={"DeviceID"}
            onChange={(e) => setBinomoDevice(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: binomoToken,
              password: binomoDevice,
              key: "binomotoken",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Stockity Token</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Token"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setStockityToken(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={"DeviceID"}
            onChange={(e) => setStockityDevice(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: stockityToken,
              password: stockityDevice,
              key: "stockitytoken",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>
      
<CardContainer>
        <SubTitle>Pocket Token</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={pocketToken}
            label={"Token"}
            onChange={(e) => setPocketToken(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              token: pocketToken,
              key: "pockettoken",
            })
          }
          variant="contained"
        >
          Salvar
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>Quotex Google</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailQuotexGoo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassQuotexGoo(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailQuotexGoo,
              password: passQuotexGoo,
              key: "quotexgoo",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>


      <CardContainer>
        <SubTitle>Binolla Google</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailBinollaGoo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassBinollaGoo(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailBinollaGoo,
              password: passBinollaGoo,
              key: "binollagoo",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>

      <CardContainer>
        <SubTitle>IQ Google</SubTitle>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            label={"Email"}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmailIQGoo(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type={"text"}
            autoComplete='off'
            label={t("senha")}
            onChange={(e) => setPassIQGoo(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button
          onClick={() =>
            handleSaveOptions({
              email: emailIQGoo,
              password: passIQGoo,
              key: "iqgoo",
            })
          }
          variant="contained"
        >
          <Translator path="buttonSalvar"/>
        </Button>
      </CardContainer>
*/
