import React, { useEffect } from "react";

import { ChannelContainer, Container, Divider, Title } from "./styles";
import { useUser } from "../../contexts/UserContext";
import TraderMetaConfig from "../TraderMetaConfig";
import Translator from "../../components/i18nComponents/Translator";

export default function ConfigureTraderData() {
  const { metaTraderData, getMetaTraderData } = useUser();

  useEffect(() => {
    getMetaTraderData();
  }, []);

  return (
    <Container>
      <Title><Translator path="paginas.copyTrader.TitleMetaTrader"/></Title>
      <Divider />

      <ChannelContainer>
        {metaTraderData.map((trader, index) => (
          <TraderMetaConfig trader={trader} key={index} />
        ))}
      </ChannelContainer>
    </Container>
  );
}
