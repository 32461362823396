// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    // Mesmo valor usado nas configurações (defaultNS)

    // Valores universais
    buttonSalvar: "Guardar",
    snackbarSucess: "Guardado",
    buttonEnviar: "Enviar",
    senha: "Contraseña",
    buttonSendMetaReq: "Envia petición",

    // Componentes
    componentes: {
      MiniDrawer: {
        textUser: "Usuario",
        textRobo: "Robot",

        linksConfig: "Configuraciones",
        linksComprarRobo: "Comprar Robot",
      },

      // Alert Componente
      AlertsComponente: {
        //exemplo:"texto de ejemplo"
      },
      // Fim Alert Componente

      // ChannelCardComponente
      ChannelCardComponente: {
        // Textos sueltos en el componente
        TextoSalvo: "Guardado",
        ButtonRemover: "Eliminar",
        textCheckox: "Conectado",
        accDemoCheckox: "Aceitar demo",
        sendTraderCheckox: "Bot Principal",

        // Texto del Alert al final del else
        handleSaveElse: "Solo el trader puede eliminar salas",

        // Textos de etiqueta
        TextFieldMultiplicador: "Multiplicador",
        TextFieldEntrada: "Entrada:",
        TextFieldFiltro: "Filtro:",
        TextFieldIgnorar: "Ignorar:",

        // Textos del select SinalTipo
        SelectSinalTipo: {
          // Etiqueta
          labelSinalTipo: "Tipo de señal",
          // Elementos
          MenuItens: {
            MenuItem1: "Programar",
            MenuItem2: "Listas",
            MenuItem3: "Inmediato",
            MenuItem4: "Inmediato anterior",
            MenuItem5: "Inmediato posterior",
            MenuItem6: "Próxima vela inmediata",
            MenuItem7: "Final de la vela",
            MenuItem8: "Minuto corriendo",
            MenuItem9: "Intervalo",
          },
        },

        candleColorTipo: {
          // Label
          labelCandleColor: "candleColors",
          // Itens
          MenuItens: {
            MenuItem1: "Desativado",
            MenuItem2: "Retracción",
            MenuItem3: "Fluir",
          },
        },

        // Textos del select Corretora
        SelectCorretora: {
          // Etiqueta
          labelCorretora: "Corredora",
          // Elementos
          MenuItens: {
            MenuItem1: "Todas",
            MenuItem2: "IQ",
            MenuItem3: "Deriv",
            MenuItem4: "DayProfit",
            MenuItem5: "CapitalBear",
            MenuItem6: "Quotex",
            MenuItem7: "Pocket",
            MenuItem8: "Mango",
            MenuItem9: "Exnova",
            MenuItem10: "Binomo",
            MenuItem11: "Stockity",
            MenuItem12: "Binolla",
            MenuItem13: "Olymp 1",
            MenuItem14: "Olymp 2",
            MenuItem15: "Bullex",
          },
        },
      },
      // Fim ChannelCardComponente -----

      // ReaderParams
      ReaderParams: {
        textFusoHorario: "Zona horaria:",
        textOrdemSimultaneas: "Órdenes Simultáneas:",
        textFiltroHorarioInicio: "Filtro horario inicio 1:",
        textFiltroHorarioFinal: "Filtro horario fin 1:",
        textFiltroHorarioInicio2: "Filtro horario inicio 2:",
        textFiltroHorarioFinal2: "Filtro horário fim 2:",
        textFiltroHorarioInicio3: "Filtro horario inicio 3:",
        textFiltroHorarioFinal3: "Filtro horário fim 3:",
        textFiltroHorarioReset: "Horário para reiniciar el bot:",
        textFiltroHorarioZeraPl: "Horário para reiniciar el resultado:",
      },
      // Fim ReaderParams

      // GlobalParamsScreen
      globalParamsScreen: {
        formLabelConta: "Tipo de cuenta:",
        radioGroupConta: {
          ContaReal: "Cuenta real",
          ContaDemo: "Cuenta demo",
        },

        formLabelOpcao: "Tipo de opción",
        radioGroupOpcao: {
          Binarias: "Binarias",
          Digitais: "Digitales",
          Ambas: "Mayor Pago",
          BinDig: "Binaria/Digital",
        },

        formLabelEntrada: "Tipo de entrada:",
        radioGroupEntrada: {
          ValorFixo: "Valor fijo $",
          Percentual: "Porcentaje de cuenta",
          CopyPerc: "Copiar trader %",
        },

        formLoginMetodo: "Metodo de login",
        formStrategySel: "Gerenciamento",
        formTypeOperSel: "Tipo de operação",

        textEntrada: "Entrada:",
        textGaleNum: "Nivel de Martingale",
        textGale: "Coeficiente",

        textWinStop: "Tipo de Ganancia/Stop:",
        MenuItemWinStop: {
          ValorFixo: "Valor fijo $",
          Percentual: "Porcentaje de cuenta",
          Diferencial1: "Diferencial loss",
          Diferencial2: "Diferencial win+loss",
          Quant: "Cantidad",
          Movel: "Flotante",
        },
      },
      // Fim GlobalParamsScreen

      // LossRecoverScreen
      LossRecoverScreen: {
        textCiclos: "Nivel de Ciclos:",
        textFatorCiclos: "Coenfiente do Ciclos:",
        textGaleNum: "Martingale:",
        textFatorGale: "Coenfiente do Gale",
        textBancaMinima: "Banca Mínima:",
        textNumeroHits: "Número de Éxitos",
        textPosLossEntrada: "PosLoss Entrada:",
        textPosLossFator: "Coenfiente do PosLoss:",
        textSoros: "Nivel de Soros:",
        textSorosGale: "MartinGale:",
        textSorosPercentual: "Soros Porcentaje:",
        textRecoverLoss: "Factor de recuperación",
        textRecoverProfit: "Lucro p/ Reiniciar",
        textRecoverMode: "Modo",
        textRecoverMode1: "Conservador",
        textRecoverMode2: "Moderado",
        textRecoverMode3: "Agresivo",
      },
      // Fim LossRecoverScreen
    },
    // Fim Componentes -------

    // Páginas
    paginas: {
      // Register
      register: {
        // Alert
        messageErrorDados: "Por favor, ingrese los datos correctamente!!",
        messageCadastroOk: "Registro exitoso!",
        messageEmailCadastrado: "Correo electrónico ya registrado",
        messageIdCadastrado: "ID ya registrado",
        messageErroCadastro: "Error en el registro",

        // Textos sueltos en la página
        titlePagina: "Pantalla de Registro",
        subtitlePagina: "Ingrese los datos de registro!!",

        // Etiquetas TextField
        textFields: {
          senha: "Contraseña:",
          confirmeSenha: "Confirme su contraseña:",
          userId: "ID de Telegram",
        },

        // Botón registrarse
        buttonCadastrar: "Registrarse",

        // Snackbar
        snackbarCadastroOk: "Registro exitoso!",

        // Textos de configuración
        config: {
          email: "El campo de correo electrónico no puede estar vacío.",
          password: "El campo de contraseña no puede estar vacío.",
          confirmPassword:
            "El campo de confirmación de contraseña es diferente al ingresado.",
          confirmPasswordNull:
            "El campo de confirmación de contraseña no puede estar vacío.",
          userId: "El campo de ID de Telegram no puede estar vacío.",
        },
      },
      // Fim register ------------

      // Reader config
      readerConfig: {
        titlePagina: "Parámetros del Lector",

        // El resto de la página está en el componente ReaderParams
      },
      // Fim Reader config ------------

      // ProxyConfig
      proxyConfig: {
        Title: "Parámetros de Proxy",
        textProxyendereco: "Dirección de Proxy:",
        textProxyPorta: "Puerto de Proxy:",
        textPassword: "Contraseña:",
      },
      // Fim ProxyConfig

      // NotFound (404)
      notFound: {
        Title: "Página no encontrada",
        buttonHome: "Volver a la página de inicio",
      },
      // Fim NotFound

      // MasanielloConfig
      masanielloConfig: {
        messageSaved: "Parámetros guardados",

        Title: "Gestión de Masaniello",

        textCapital: "Capital:",
        textTotalOperacoes: "Total de Operaciones:",
        textNumeroVitorias: "Número de Victorias:",
        textValorAlvo: "Ganancia Objetivo:",
        textValorHit: "Hits p/ Reiniciar:",

        textTipo: "Tipo:",

        MenuItensTipo: {
          Desativado: "Desactivado",
          Normal: "Normal",
          Progressive: "Progresivo",
        },

        textModo: "Modo:",

        MenuItensModo: {
          UmSinal: "Una señal",
          MultiploSinais: "Múltiples señales",
        },

        CheckboxReiniciar: "Reiniciar se o capital é perdido",
      },
      // Fim MasanielloConfig

      // GeralConfig
      geralConfig: {
        titleGerais: "Parámetros Generales",
        titleRecuperacao: "Parámetros de Recuperación",

        // El resto de la página está en el componente GlobalParamsScreen
        // El resto de la página está en el componente LossRecoverScreen
      },
      // Fim GeralConfig

      addPhone: {
        Title: "Conectar a Telegram",

        textAddTelefone: "Agregar Teléfono:",
        textAddTelegram: "Código de Telegram:",
        textSenhaTelegram: "Contraseña de Telegram:",
      }, //Fim addPhone

      // AuthCode
      authCode: {
        Subtitle: "Ingrese correo electrónico y contraseña",

        labelSenha: "Contraseña",

        linkRegistro: "Regístrese aquí",
      }, //Fim authCode

      // BrokerConfig
      BrokerConfig: {
        Title: "Corredoras",
      }, //Fim BrokerConfig

      // BuyRobot
      buyRobot: {
        textTipoDePlano: "Tipo de plan",
        // días
        dias: {
          plano1: "1 bot 7 días: R$40",
          plano2: "1 bot 15 días: R$60",
          plano3: "1 bot 30 días: R$80",
          plano4: "1 bot 90 días: R$180",
          plano5: "1 bot 180 días: R$350",
          plano6: "2 bots 30 días: R$100",
          plano7: "2 bots 90 días = R$240",
          plano8: "3 bots 30 días = R$110",
          plano9: "3 bots 90 días = R$270",
          plano10: "4 bots 30 días = R$120",
          plano11: "4 bots 90 días = R$300",
        },

        Title: "Pago",

        textMetodo: "Método",
        metodoItens: {
          MercadoPago: "Mercado Pago",
          Hotmart: "Hotmart",
          Pix: "Pix",
        },

        buttonGerarLink: "Generar Enlace",

        linkPagamento: "Enlace de Pago",

        // Etiqueta
        labelIdTelegram: "Su ID de Telegram:",
        labelCPF: "CPF (sin puntos ni guiones):",
        labelVendedor: "ID del Vendedor:",

        // Alerta
        messageDadosIncorretos: "Por favor, ingrese los datos correctamente!",
        messageCpf: "Por favor, ingrese el CPF",
      }, //Fim BuyRobot

      // ChatList
      channelList: {
        messageAtualizando: "Actualizando... espere unos segundos",
        messageApenasTrader: "Solo el trader puede actualizar/guardar",

        title: "Grupos y Canales",

        textAguarde: "Espere unos segundos!",
        buttonListar: "Listar",
      },
      // Fim ChatList

      // Configure
      configure: {
        Title: "Configuración del Robot",

        linkCorretora: "Corredoras",
        linkGeral: "Configuración General",
        linkCopySignals: "Copiar Señales",
        linkCopyTrade: "Copy Trader",
        linkMasaniello: "Masaniello",
        linkProxy: "Configurar Proxy",
        linkComprarRobo: "Comprar el Robot",
        linkSuporte: "Suporte sonnybot",
        linkCopyMetatrader: "Copy Metatrader",
        linkBotcommands: "Comandos do bot",
        linkBotsPage: "Bots",
      },

      Botspage: {
        Title: "Bots",

        linkBotcommands: "Comandos do bot",
        linkBotManager: "Gerenciar bots",
      },

      Strategies: {
        Title: "Estratégias",

        linkMasaniello: "Masaniello",
        linkCiclos: "Ciclos",
        linkSoros: "Soros",
        linkRecoverPerc: "Recuperación de pérdidas",
        linkCustomCycle: "Personalizado",
      },

      CustomCycles: {
        subTitle: "Personalizado",
      },

      // Fim Configure

      // ConfigureChannels
      configureChannels: {
        Title: "Configurar Salas",
      },
      // Fim configureChannels

      // CopySignals
      copySignals: {
        Title: "Configuración de Copiar Señales",

        linkAddPhone: "Agregar Teléfono",
        linkChannelList: "Agregar Salas",
        linkConfigSala: "Configurar Salas",
        linkParametros: "Parámetros del Lector",
        linkCopyTrader: "Copiar Salas",

        buttonReset: "Reiniciar",
        buttonDeletar: "Eliminar",
      },
      // Fim CopySignals

      // CopyTrader
      copyTrader: {
        TitleMain: "Configuración Copytrader",
        TitleTrader: "Configuración Trader",
        TitleSeguidor: "Configuración Seguidor",
        TitleMetaReq: "Seguir una Trader",
        Title: "Configuración Seguidor",

        LoteType: "Tipo de entrada",
        LoteType1: "Entrada fixa",
        LoteType2: "Copy trade",

        TitleMetaCopy: "Configuración MetaCopy",
        TitleMetaTrader: "Configuración Metatrader",
        TitleTraderFollowers: "Lista de Seguidores",

        textHabilitar: "Habilitar Copiar Trader",
        textListaSeguidores: "Lista de Seguidores",

        textSeguidor: "Seguidor",

        buttonRemover: "Eliminar",
        buttonDesativar: "Desactivar",

        textOrdemSimultaneas: "Ordens Simultâneas:",
        textMinValue: "Entrada Minima:",
        textMaxValue: "Entrada Máxima:",
        maturity: "Madurez",
        typeOfBot: "Tipo de robot",
        actions: "Comportamiento",
      },
      // Fim CopyTrader

      // CopyTraderSignals
      CopyTraderSignals: {
        subTitle: "Configuración Copiar Trader",
        subTitle2: "Configuración Copiar salas",
        subTitleMetaReq: "Enviar Solicitud de MetaCopy",
        subTitleCopyReq: "Enviar Solicitud de CopyTrader",

        textIdTrader: "ID del Trader:",
        textUserName: "Su Nombre(Sin espacios/acentos)",
      },

      // Fim CopyTraderSignals
    },
    // Fim Páginas
  },
};
