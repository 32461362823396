import React, { useState } from "react";

import { Box } from "@mui/system";
import { Checkbox, TextField, Select, MenuItem } from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import Snackbar from "../../components/Snackbar";
import Translator from "../i18nComponents/Translator"
import {
  Button,
  CardContainer,
  Divider,
  InputContainer,
  SubTitle,
} from "./styles";
import { useTranslation } from "react-i18next";

export default function ChannelCard({ channel }) {
// Tradução i18n
const {t} = useTranslation()
//--------
  const { handleChannConfig, handleChannelRemove } = useUser();

  const [enable, setEnable] = useState(channel.chanEnable);
  const [lote, setLote] = useState(channel.lote);
  const [galenum, setGalenum] = useState(channel.galenum);
  const [galenumFat, setGalenumFat] = useState(channel.galefat);
  const [wordFilter, setWordFilter] = useState(channel.wordfilter);
  const [ignoreWord, setIgnore] = useState(channel.ignore);
  const [delaySig, setDelaySig] = useState(channel.delaysig);
  const [signalType, setSignalType] = useState(channel.signaltype);
  const [brokerID, setBrokerID] = useState(channel.brokerid);
  const [timeframe, setTimeframe] = useState(channel.timeframe);
  const [timezone, setTimezone] = useState(channel.timezone);
  const [strategySel, setStrategySel] = useState(channel.strategySel);
  const [send2Trader, setSend2Trader] = useState(channel.send2Trader);
  //
  const [botID, setBotID] = useState(channel.botID);
  //
  const [saveStateOpen, setSaveStateOpen] = useState(false);
  //const [updateStateOpen, setUpdateStateOpen] = useState(false);
  const userid = localStorage.getItem("userId");
  const traderid = localStorage.getItem("traderId");
  const botIDs = JSON.parse(localStorage.getItem("botIDs"));

  // Adicionar '0' como o primeiro valor no array botIDs
  botIDs.unshift({ userid: '0', traderid: '0' });

  const handleSave = async (btn) => {

    if (btn === 1) {

      if (delaySig < 0) {
        alert("Delay precisa ser maior que 0");
        return;
      }
      //
      if (wordFilter.includes(",") || ignoreWord.includes(",")) {
        alert(t('errors.useSemicolon'));
        return;
      }
      //
      if (lote < 0 || galenum < 0 || galenumFat < 0 || timeframe < 0) {
          alert("Invalido - não use valor negativo");
          return;
      }
      //
      await handleChannConfig({
        chanEnable: enable,
        lote: lote,
        galenum: galenum,
        galefat: galenumFat,
        idNum: channel.idNum,
        wordfilter: wordFilter,
        delaysig: delaySig,
        signaltype: signalType,
        brokerid: brokerID,
        ignore: ignoreWord,
        timeframe: timeframe,
        timezone: timezone,
        strategySel: strategySel,
        traderid: traderid,
        send2Trader: send2Trader,
        botID: botID,
      });

      setSaveStateOpen((prevState) => !prevState);

    } else {
      //
      if (userid === traderid) {
        //
        handleChannelRemove(channel.idNum);
        setSaveStateOpen((prevState) => !prevState);

        window.location.reload();

      } else {
        // i18n
        const translatedMessage = t('componentes.ChannelCardComponente.handleSaveElse');
        alert(translatedMessage);
      }
    }
  };

  const handleSignal = (event) => {
    setSignalType(event.target.value);
  };

  const handleBrokerID = (event) => {
    setBrokerID(event.target.value);
  };

  return (
    <CardContainer>
      <SubTitle>{channel.name}</SubTitle>
      <Divider />

      <InputContainer>
        <Checkbox
          checked={enable}
          name={"chanEnable"}
          id={"idEn"}
          onChange={() => setEnable(!enable)}
        />
        <div>{t("componentes.ChannelCardComponente.textCheckox")}</div>
      </InputContainer>

      {userid === traderid && (
      <InputContainer>
        <Checkbox
          checked={send2Trader}
          name={"send2Trader"}
          onChange={() => setSend2Trader(!send2Trader)}
        />
        <div>{t("componentes.ChannelCardComponente.sendTraderCheckox")}</div>
      </InputContainer>
      )}

      <Box
        component="form"
        sx={{
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idLote"
          name="lote"
          label={t('componentes.ChannelCardComponente.TextFieldEntrada')}
          type="number"
          defaultValue={lote}
          onChange={(e) => {
            setLote(e.target.value);
          }}
          variant="filled"
        />

        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idGale"
          name="galenum"
          label="Martingale: "
          type="number"
          defaultValue={galenum}
          onChange={(e) => {
            setGalenum(e.target.value);
          }}
          variant="filled"
        />

        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idGale"
          name="galenumfat"
          label={t('componentes.ChannelCardComponente.TextFieldMultiplicador')}
          type="number"
          defaultValue={galenumFat}
          onChange={(e) => {
            setGalenumFat(e.target.value);
          }}
          variant="filled"
        />

        {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idDelay"
          name="delaysig"
          label="Delay: "
          type="number"
          defaultValue={delaySig}
          onChange={(e) => setDelaySig(e.target.value)}
          variant="filled"
        />
        )}

      {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          label="Timeframe: "
          type="number"
          defaultValue={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
          variant="filled"
        />
        )}

      {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          label="Timezone: "
          type="number"
          defaultValue={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          variant="filled"
        />
        )}

      {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idFilter"
          name="wordfilter"
          label={t('componentes.ChannelCardComponente.TextFieldFiltro')}
          type="text"
          defaultValue={wordFilter}
          onChange={(e) => setWordFilter(e.target.value)}
          variant="filled"
        />
        )}

        {userid === traderid && (
        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          label={t('componentes.ChannelCardComponente.TextFieldIgnorar')}
          type="text"
          defaultValue={ignoreWord}
          onChange={(e) => setIgnore(e.target.value)}
          variant="filled"
        />
        )}

      </Box>

      {userid === traderid && (
      <Select
          labelId="demo-select-small"
          value={signalType}
          label={t('componentes.ChannelCardComponente.SelectSinalTipo.labelSinalTipo')}
          defaultValue={1}
          onChange={handleSignal}
          required
        >
          <MenuItem value={1}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem1"/></MenuItem>
          <MenuItem value={2}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem2"/></MenuItem>
          <MenuItem value={3}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem3"/></MenuItem>
          <MenuItem value={4}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem4"/></MenuItem>
          <MenuItem value={5}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem5"/></MenuItem>
          <MenuItem value={6}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem6"/></MenuItem>
        </Select>
      )}

      {userid === traderid && (
        <Select
          labelId="demo-select-small"
          value={brokerID}
          label={t('componentes.ChannelCardComponente.SelectCorretora.labelCorretora')}
          defaultValue={0}
          onChange={handleBrokerID}
          required
        >
          <MenuItem value={0}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem1"/></MenuItem>
          <MenuItem value={1}>IQ</MenuItem>
          <MenuItem value={2}>Deriv</MenuItem>
          <MenuItem value={3}>DayProfit</MenuItem>
          <MenuItem value={4}>CapitalBear</MenuItem>
          <MenuItem value={5}>Quotex</MenuItem>
          <MenuItem value={6}>Pocket</MenuItem>
          <MenuItem value={7}>Avalon</MenuItem>
          <MenuItem value={8}>Exnova</MenuItem>
          <MenuItem value={9}>Binomo</MenuItem>
          <MenuItem value={10}>Stockity</MenuItem>
          <MenuItem value={11}>Binolla</MenuItem>
          <MenuItem value={12}>Olymp 1</MenuItem>
          <MenuItem value={13}>Olymp 2</MenuItem>
          <MenuItem value={14}>Bullex</MenuItem>
          <MenuItem value={15}>Optinary</MenuItem>
          <MenuItem value={16}>Broker10</MenuItem>
          <MenuItem value={17}>Pixtrade</MenuItem>
          <MenuItem value={18}>Optgo</MenuItem>
          <MenuItem value={19}>Polarium</MenuItem>
          <MenuItem value={20}>Homebroker</MenuItem>
        </Select>
      )}

      {userid === traderid && (
      <Select
        value={botID}
        label="Bot ID"
        onChange={(e) => setBotID(e.target.value)}
        required
      >
        {botIDs.map((botIDUser) => (
          <MenuItem value={botIDUser.userid}>
            {botIDUser.userid}
          </MenuItem>
        ))}
      </Select>
      )}

      {userid === 0 && (
      <Select
          labelId="demo-select-small"
          value={signalType}
          label={t('componentes.ChannelCardComponente.SelectSinalTipo.labelSinalTipo')}
          value={strategySel}
          onChange={(e) => setStrategySel(e.target.value)}
          required
        >
          <MenuItem value={0}>Nenhuma</MenuItem>
          <MenuItem value={1}>Martigale</MenuItem>
          <MenuItem value={2}>Masaniello</MenuItem>
          <MenuItem value={3}>Ciclos</MenuItem>
          <MenuItem value={4}>Recuperação de loss</MenuItem>
        </Select>
      )}

      <Snackbar
        type="success"
        open={saveStateOpen}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="componentes.ChannelCardComponente.TextoSalvo"/>
      </Snackbar>

      <Button onClick={() => handleSave(1)}><Translator path="buttonSalvar"/></Button>
      <Button onClick={() => handleSave(2)}><Translator path="componentes.ChannelCardComponente.ButtonRemover"/></Button>
    </CardContainer>
  );
}
