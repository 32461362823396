import React, { useEffect, useState } from "react";
import { Checkbox, TextField, Select, MenuItem } from "@mui/material";
import {
  Button,
  CardContainer,
  InputContainer,
  SubTitle,
} from "./styles";

import Snackbar from "../../components/Snackbar";
import { Box } from "@mui/system";
import { useUser } from "../../contexts/UserContext";
import Translator from "../../components/i18nComponents/Translator";
import { useTranslation } from "react-i18next";

export default function FollowerMetaConfig( {trader} ) {

  const {t} = useTranslation()

  const [enable, setEnable] = useState(trader.traderEna);
  const [traderID, setTraderID] = useState(trader.traderID);
  const [maxTrades, setMaxTrades] = useState(trader.maxTrades);
  const [signalType, setSignalType] = useState(trader.signalType);
  const [delaySig, setDelaySig] = useState(trader.delaysig);
  const [brokerID, setBrokerID] = useState(trader.brokerID);
  const [anticipation, setAnticip] = useState(trader.anticipation);
  //
  const [saveStateOpen, setSaveStateOpen] = useState(false);

  const { sendMetaFollowerData, removeMetaFollowerData } = useUser();

  const handleSignal = (event) => {
    setSignalType(event.target.value);
  };

  const handleBrokerID = (event) => {
    setBrokerID(event.target.value);
  };

  const handleSave = async (btn) => {
    if (btn === 1) {
      //
      await sendMetaFollowerData({
        traderEna: enable,
        traderID: traderID,
        maxTrades: maxTrades,
        signalType: signalType,
        delaysig: delaySig,
        brokerID: brokerID,
        anticipation: anticipation,
      });
      //
      setSaveStateOpen((prevState) => !prevState);
    } else {
      //
      removeMetaFollowerData(traderID);
      setSaveStateOpen((prevState) => !prevState);
      window.location.reload();
    }
  };

  return (
    
    <CardContainer>

      <SubTitle>ID: {traderID}</SubTitle>
      <InputContainer>
        <Checkbox
          checked={enable}
          name={"chanEnable"}
          id={"idEn"}
          onChange={() => setEnable(!enable)}
        />
        <div>{t("componentes.ChannelCardComponente.textCheckox")}</div>
      </InputContainer>

      <Box
        component="form"
        sx={{
          maxWidth: "100%",
        }}
        noValidate
        autoComplete="off"
      >

        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idMaxTrades"
          name="maxTrades"
          label="Limite de Ordens"
          type="number"
          defaultValue={maxTrades}
          onChange={(e) => setMaxTrades(e.target.value)}
          variant="filled"
        />

      <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          id="idDelay"
          name="delaysig"
          label="Delay: "
          type="number"
          defaultValue={delaySig}
          onChange={(e) => setDelaySig(e.target.value)}
          variant="filled"
        />

        <TextField
          fullWidth
          sx={{ marginBottom: "15px" }}
          name="anticipation"
          label="Antecipação: "
          type="number"
          defaultValue={anticipation}
          onChange={(e) => setAnticip(e.target.value)}
          variant="filled"
        />

      </Box>

      <Select
          labelId="demo-select-small"
          value={signalType}
          label={t('componentes.ChannelCardComponente.SelectSinalTipo.labelSinalTipo')}
          defaultValue={7}
          onChange={handleSignal}
          required
        >
          <MenuItem value={4}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem4"/></MenuItem>
          <MenuItem value={5}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem5"/></MenuItem>
          <MenuItem value={6}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem6"/></MenuItem>
          <MenuItem value={7}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem7"/></MenuItem>
          <MenuItem value={8}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem8"/></MenuItem>
        </Select>

      <Select
          labelId="demo-select-small"
          value={brokerID}
          label={t('componentes.ChannelCardComponente.SelectCorretora.labelCorretora')}
          defaultValue={0}
          onChange={handleBrokerID}
          required
        >
          <MenuItem value={0}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem1"/></MenuItem>
          <MenuItem value={1}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem2"/></MenuItem>
          <MenuItem value={2}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem3"/></MenuItem>
          <MenuItem value={3}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem4"/></MenuItem>
          <MenuItem value={4}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem5"/></MenuItem>
          <MenuItem value={5}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem6"/></MenuItem>
          <MenuItem value={6}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem7"/></MenuItem>
          <MenuItem value={7}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem8"/></MenuItem>
          <MenuItem value={8}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem9"/></MenuItem>
          <MenuItem value={9}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem10"/></MenuItem>
          <MenuItem value={10}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem11"/></MenuItem>
          <MenuItem value={11}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem12"/></MenuItem>
          <MenuItem value={12}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem13"/></MenuItem>
        </Select>

      <Snackbar
        type="success"
        open={saveStateOpen}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="componentes.ChannelCardComponente.TextoSalvo"/>
      </Snackbar>

      <Button onClick={() => handleSave(1)}><Translator path="buttonSalvar"/></Button>
      <Button onClick={() => handleSave(2)}><Translator path="componentes.ChannelCardComponente.ButtonRemover"/></Button>
    
    </CardContainer>
  );
}
