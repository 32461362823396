import {React, useState } from "react";

import { Container, SubContainer, SubTitle, Divider } from "./styles";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useUser } from "../../contexts/UserContext";

import Translator from "../../components/i18nComponents/Translator";
import { useTranslation } from "react-i18next";

export default function FollowerMetaReq() {

    const { sendMetaIDRequest } = useUser();
    const [traderid, setTraderID] = useState(null);
    const [userName, setUserName] = useState(null);
    //const [saveStateOpen, setSaveStateOpen] = useState(false);

    const userid = localStorage.getItem("userId");

    const handleSave = async () => {
        if (traderid) {
            //
            //console.log('traderid = ', traderid);

          if (traderid && userName) {

            //setSaveStateOpen((prevState) => !prevState);
            sendMetaIDRequest(userid, traderid, userName.trim());

          } else {
            alert("Preencha Trader ID e Nome");
          }
            //
          window.location.assign("/configmetatrader");
        }
    }
  return (
    <Container>

      <SubTitle><Translator path="paginas.CopyTraderSignals.subTitleMetaReq"/></SubTitle>
      <Divider />
      <br />

      <SubContainer>
        <div><Translator path="paginas.CopyTraderSignals.textIdTrader"/></div>

        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={traderid}
            type="number"
            onChange={(e) => {
                setTraderID(e.target.value);
            }}
            variant="filled"
          />
        </Box>

      </SubContainer>

      <SubContainer>
        <div><Translator path="paginas.CopyTraderSignals.textUserName"/></div>

        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={userName}
            type="text"
            onChange={(e) => {
                setUserName(e.target.value);
            }}
            variant="filled"
          />
        </Box>

      </SubContainer>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSave()}
        variant="contained"
      ><Translator path="buttonSendMetaReq"/></Button>
    </Container>
  );
}
