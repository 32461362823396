import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, TextField } from "@mui/material";

import { SubContainer, Divider } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import Translator from "../../components/i18nComponents/Translator";

export default function ConfigureCycles() {

  const { sendCyclesParams, getCyclesParams, cyclesParams, userId } = useUser();

  const [ciclos, setCiclos] = useState(null);
  const [brokerID, setBrokerID] = useState(null);
  const [ciclosFat, setCiclosFat] = useState(null);
  const [ciclosGale, setCiclosGale] = useState(null);
  const [ciclosGaleFat, setCiclosGaleFat] = useState(null);
  const [saveStateOpen, setSaveStateOpen] = useState(false);
  //

  const handleSaveParamsRecv = useCallback(async () => {
    //
    setSaveStateOpen((prevState) => !prevState);
    //
    if (ciclos < 0 || ciclosFat < 0 || ciclosGale < 0 || ciclosGaleFat < 0) {
        alert("Parametros invalidos");
        return;
    }
    //
    await sendCyclesParams({
      ciclos,
      ciclosFat,
      ciclosGale,
      ciclosGaleFat,
      brokerID,
    });
  }, [
    sendCyclesParams,
      ciclos,
      ciclosFat,
      ciclosGale,
      ciclosGaleFat,
      brokerID,
  ]);

  const handleSetRecoveryParams = (data) => {
    setCiclos(data.ciclos);
    setCiclosFat(data.ciclosFat);
    setCiclosGale(data.ciclosGale);
    setCiclosGaleFat(data.ciclosGaleFat);
  };

  useEffect(() => {
    if (cyclesParams)  {
      handleSetRecoveryParams(cyclesParams);
    }
  }, [cyclesParams]);

  useEffect(() => {
    if (userId) {
      var brokerIDX = localStorage.getItem("brokerID");
      if (brokerIDX === null) {
        brokerIDX = 1;
      }
      setBrokerID(brokerIDX);
      getCyclesParams(brokerIDX);
    }
  }, [userId]);


  return (
    <>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textCiclos"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclos}
            type="number"
            onChange={(e) => {
              setCiclos(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textFatorCiclos"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclosFat}
            type="number"
            onChange={(e) => {
              setCiclosFat(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
      <div><Translator path="componentes.globalParamsScreen.textGaleNum"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclosGale}
            type="number"
            onChange={(e) => {
              setCiclosGale(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textFatorGale"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={ciclosGaleFat}
            type="number"
            onChange={(e) => {
              setCiclosGaleFat(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>
      <Divider />

      <Snackbar
        type="success"
        open={saveStateOpen}
        autoHideDuration={2000}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParamsRecv()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>

    </>
  );
}
