import React, { useEffect, useState } from "react";

import {
  Container,
  Title,
  Divider,
  Button,
} from "./styles";

import Translator from "../../components/i18nComponents/Translator";

export default function ConfigureMetaTrader() {

  return (
    <Container>
      <Title><Translator path="paginas.copyTrader.TitleMetaCopy"/></Title>
      <Divider />

      <Button>
        <a href="/tradermetaconfig"><Translator path="paginas.copyTrader.TitleMetaTrader"/></a>
      </Button>

      <Button>
        <a href="/followerslist"><Translator path="paginas.copyTrader.TitleTraderFollowers"/></a>
      </Button>

      <Divider />

      <Button>
        <a href="/configmetafollower"><Translator path="paginas.copyTrader.TitleSeguidor"/></a>
      </Button>

      <Button>
        <a href="/followermetareq"><Translator path="paginas.copyTrader.TitleMetaReq"/></a>
      </Button>


      </Container>
  );
}
