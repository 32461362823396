import React, { createContext, useContext, useEffect, useState } from "react";
//import { useNavigate } from "react-router";

//const base_url = "http://191.252.110.28:3000/api";
//const base_url = "https://sonnybot.com.br/api";
//const base_url = "http://34.207.228.76:3000/api";
const base_url = process.env.REACT_APP_BASE_URL;

function success() {
  const data = JSON.parse(this.responseText); //fazer o parsing da string para JSON
  //console.log(data);
  return data;
}

// função para tratar o erro
function error(err) {
  console.log("Erro de solicitação", err); //os detalhes do erro estarão no objeto "err"
}

const xhr = new XMLHttpRequest(); //invocar uma nova instância de XMLHttpRequest
xhr.onload = success; // chamar a função success se a solicitação for um sucesso
xhr.onerror = error; // chamar a função error se a solicitação der errado

export const UserContext = createContext({});

export const UserProvider = (props) => {
  const [userId, setUserId] = useState(null);
  const [traderId, settraderId] = useState(null);
  //
  const [userEmail, setUserEmail] = useState(null);
  const [userPass, setUserPass] = useState(null);
  //const [botIDs, setBotIDs] = useState(null);
  const [paymentLink, setPaymentLink] = useState("");

  const [listGroups, setListGroups] = useState([]);
  const [seguidores, setSeguidores] = useState([]);
  const [metaFollersData, setMetaFollowersData] = useState([]);
  const [metaTraderData, setMetaTraderData] = useState([]);
  const [traderInfo, setTraderInfo] = useState([]);
  const [metaFollers, setMetaFollowers] = useState([]);
  const [tradersList, setTradersList] = useState([]);
  const [geralConfig, setgeralConfig] = useState([]);
  const [recoveryParams, setRecoveryParams] = useState([]);
  const [cyclesParams, setCyclosParams] = useState([]);
  const [sorosParams, setSorosParams] = useState([]);
  const [lossRecParams, setLossRecParams] = useState([]);
  const [readerConfig, setReaderConfig] = useState([]);
  const [masaParams, setMasParams] = useState([]);

  const [listChannels, setListChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);

  //const [alert, setAlert] = useState(null);
  const [plane, setPlane] = useState(null);

  const handleLogin = async (email, password) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
        uitoken:
          "4c1d09785d8334b443aae16acda90ee11b2f74ba86c8fcc39ab718befc04c101",
      }),
    };

    const response = await fetch(`${base_url}/botlogin`, options);
    const data = await response.json();
    //.then((resp) => resp.json())
    //.catch((error) => {
    //console.log("Error", error);
    //});

    //if (typeof response == "undefined")
    //return;

    if (response.status === 200) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
      //
      if (typeof data === "object" && data !== null) {
        localStorage.setItem("userId", data[0].userid);
        localStorage.setItem("traderId", data[0].traderid);
        localStorage.setItem("botIDs", JSON.stringify(data));
      } else {
        localStorage.setItem("userId", data.userid);
        localStorage.setItem("traderId", data.traderid);
      }

      setUserEmail(email);
      setUserPass(password);

      window.location.assign("/");
    } else if (data.msg == "invalid_email_password") {
      alert("Email ou passowrd incorreto");
    }
  };

  const handleLoggout = async () => {
    localStorage.clear();
    setUserEmail(null);
    setUserPass(null);

    window.location.assign("/");
  };

  const handleGlobalParams = async (brokerID) => {
    let response = [];
    response = await fetch(
      `${base_url}/globalparams?userid=${userId}&brokerid=${brokerID}`
    ).then((resp) => resp.json());

    setgeralConfig(response);
  };

  const handleRecoveryParams = async (brokerID) => {
    let response = [];
    response = await fetch(
      `${base_url}/recoveryparams?userid=${userId}&brokerid=${brokerID}`
    ).then((resp) => resp.json());

    setRecoveryParams(response);
  };

  const handleReaderParams = async () => {
    let response = [];
    response = await fetch(`${base_url}/readerparams?userid=${userId}`).then(
      (resp) => resp.json()
    );

    setReaderConfig(response);
  };

  const handleMasanielloParams = async () => {
    let response = [];
    response = await fetch(`${base_url}/masaparams?userid=${userId}`).then(
      (resp) => resp.json()
    );

    setMasParams(response);
  };

  const handleSaveParameters = async (data) => {
    const update = {
      accType: Number(data.accType),
      optionType: Number(data.optionType),
      loteType: Number(data.loteType),
      stopType: Number(data.stopType),
      lote: Number(data.lote),
      galenum: Number(data.galenum),
      galefat: Number(data.galefat),
      stopWin: Number(data.stopWin),
      stopLoss: Number(data.stopLoss),
      payout: Number(data.payout),
      brokerID: Number(data.brokerID),
      traderMode: Number(data.traderMode),
      loginMethod: Number(data.loginMethod),
      strategySel: Number(data.strategySel),
      typeOperation: Number(data.typeOperation),
      userid: userId,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(update),
    };

    await fetch(`${base_url}/globalparams?userid=${userId}`, options);
  };

  const handleSaveRecoveryParams = async (data) => {
    const update = {
      ciclos: Number(data.ciclos),
      ciclosfat: Number(data.ciclosFat),
      ciclosgale: Number(data.ciclosGale),
      ciclosgalefat: Number(data.ciclosGaleFat),
      soros: Number(data.soros),
      sorosgale: Number(data.sorosGale),
      sorosperc: Number(data.sorosPerc),
      brokerID: Number(data.brokerID),
      userid: userId,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(update),
    };

    await fetch(`${base_url}/recoveryparams?userid=${userId}`, options);
  };

  const saveReaderParams = async (
    timerzone,
    maxtrades,
    hourStart,
    hourEnd,
    hourStart2,
    hourEnd2,
    hourStart3,
    hourEnd3,
    hourReset,
    hourClearScore
  ) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
        timerzone: Number(timerzone),
        maxtrades: Number(maxtrades),
        hourStart,
        hourEnd,
        hourStart2,
        hourEnd2,
        hourStart3,
        hourEnd3,
        hourReset,
        hourClearScore,
      }),
    };
    //console.log(options.body);

    await fetch(`${base_url}/readerparams?userid=${userId}`, options);
  };

  const resetReader = async () => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
      }),
    };
    console.log(options.body);

    await fetch(`${base_url}/readerreset`, options);
  };

  const resetDelete = async () => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
      }),
    };
    console.log(options.body);

    await fetch(`${base_url}/readerdelete`, options);
  };

  const handleSaveOptions = async ({
    email = null,
    password = null,
    tokenDemo = null,
    tokenReal = null,
    token = null,
    key,
  }) => {
    let data = {};

    if (key === "iqdata") {
      data = { email, password, userid: userId };

    } else if (key === "binarydata") {
      data = { tokenDemo, tokenReal, userid: userId };

    } else if (
      key === "quotexdata" ||
      key == "quotexgoo" ||
      key == "binollagoo" ||
      key === "iqgoo"
    ) {
      data = { email, password, userid: userId };

    } else if (
      key === "capitaldata" ||
      key === "binomotoken" ||
      key === "stockitytoken"
    ) {
      data = { email, password, userid: userId };

    } else if (
      key === "avalondata" ||
      key === "exnovadata" ||
      key === "binomodata" ||
      key === "pocketdata" ||
      key === "bitnessdata" ||
      key === "binolladata" ||
      key === "olympdata" ||
      key === "dayprofitdata" ||
      key === "stockitydata" ||
      key === "bullexdata" ||
      key === "optinarydata" ||
      key === "xoptiondata" ||
      key === "pixtradedata" ||
      key === "optgodata" ||
      key === "polariumdata" ||
      key === "broker10data" ||
      key === "homebroker"
    ) {
      data = { email, password, userid: userId };

    } else if (key === "quotextoken" || key === "pockettoken") {
      data = { token, userid: userId };

    } else return;

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    //console.log("Credenciais da corretora salvas");
    alert("Credenciais da corretora salvas");
    await fetch(`${base_url}/${key}`, options);
  };

  // VERIFICAR FUNCIONALIDADE
  const handleUpdateChatList = async (data) => {
    const update = {
      userid: userId,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(update),
    };

    const response = await fetch(`${base_url}/updatechatlist`, options);
    console.log(response);
  };

  const handleGetChatList = async () => {
    const response = await fetch(
      `${base_url}/getchatlist?userid=${userId}&traderid=${traderId}`
    ).then((resp) => resp.json());

    setListChannels(response);
  };

  const chatList = [];

  const addChatOnList = (name, id) => {
    // remove non-ascii
    //name = name.replace(/[^\x00-\x7F]/g, "");
    name = name.replace("'", "");
    const data = {
      name: name,
      idNum: id,
      userid: userId,
    };
    chatList.push(data);
    console.log("ChatList", chatList);
  };

  const removeOnList = (id) => {
    chatList.map((item, index) => {
      if (item === id) chatList.splice(index, 1);
      return console.log("ChatList", chatList);
    });
  };

  const handleSaveChatList = async () => {
    const data = chatList;

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    await fetch(`${base_url}/savelist?userid=${userId}`, options);
  };

  const handleGetGroups = async () => {
    const response = await fetch(
      `${base_url}/getgroups?userid=${userId}&traderid=${traderId}`
    )
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    //console.log("Response", response);
    setListGroups(response);
  };

  const handleSendCustomCycles = async (data, setLoading) => {
    //Aqui deve ser feito o fetch para salvar os dados no backend
    setLoading(true);
    const response = await fetch(`${base_url}/savecustcycle?userid=${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Success:", result);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => setLoading(false));
  };

  const handleChannConfig = ({
    chanEnable,
    lote,
    galenum,
    galefat,
    idNum,
    wordfilter,
    delaysig,
    signaltype,
    brokerid,
    ignore,
    timeframe,
    timezone,
    strategySel,
    traderid,
    send2Trader,
    botID,
  }) => {
    const data = {
      userid: userId,
      chanEnable,
      lote: Number(lote),
      galenum: Number(galenum),
      galefat: Number(galefat),
      idNum,
      wordfilter,
      delaysig,
      signaltype: Number(signaltype),
      brokerid: Number(brokerid),
      ignore: ignore,
      timeframe: Number(timeframe),
      timezone: Number(timezone),
      strategySel: Number(strategySel),
      send2Trader: Number(send2Trader),
      traderid: traderid,
      botID: botID,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/savegroup`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const handleChannelRemove = (idNum) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
        traderid: traderId,
        idNum,
      }),
    };

    fetch(`${base_url}/chanremove`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendbrokerID = async (curBrokerID, brokersMask, multiBroker) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
        curBrokerID,
        brokersMask,
        multiBroker,
      }),
    };

    const response = await fetch(`${base_url}/brokerid`, options);
    console.log(response);
  };

  const handleSaveTraderID = async (userid, traderid) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid,
        traderid,
      }),
    };

    const response = await fetch(`${base_url}/copyid`, options);
    console.log(response);
  };

  // api para enviar requisão para seguir um trader pelo copy metatrader
  const sendMetaIDRequest = async (userid, traderid, userName) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid,
        traderid,
        userName: userName,
      }),
    };

    const response = await fetch(`${base_url}/metaidreq`, options);
    console.log(response);
  };

  const sendCopyRequest = async (userid, traderid, userName) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid,
        traderid,
        userName: userName,
      }),
    };

    const response = await fetch(`${base_url}/copytradereq`, options);
    console.log(response);
  };

  const handleBuyRobot = async (userid, subid, sellerid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const options = {
          method: "POST",
          body: JSON.stringify({
            userid,
            subid,
            sellerid,
          }),
        };

        const response = await fetch(`${base_url}/buybot`, options);
        const res = await response.text();
        console.log("Response", res);
        setPaymentLink(res);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  const addLoginCode = async (user, password, userid) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid,
        code: user,
        password,
      }),
    };

    const response = await fetch(`${base_url}/authcode`, options)
      .then((resp) => resp)
      .catch((error) => console.log("ERROR", error));

    if (response.status === 200) return true;
    return false;
  };

  const handlePhone = async (phone, userid) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid,
        phone,
      }),
    };

    const response = await fetch(`${base_url}/addphone`, options)
      .then((resp) => resp)
      .catch((error) => console.log("ERROR", error));

    console.log(response);
    if (response.status === 200) return true;

    return false;
  };

  const sendProxyParams = async (ip, porta, login, password) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
        ip,
        porta: Number(porta),
        login,
        password,
      }),
    };

    const response = await fetch(`${base_url}/proxyparams`, options)
      .then((resp) => resp)
      .catch((error) => console.log("ERROR", error));

    //console.log(response);
    if (response.status === 200) return true;

    return false;
  };

  const sendMasaParams = async (
    capital,
    totalOper,
    totalWin,
    payout,
    gale,
    typeMas,
    restart,
    target,
    modoMasa,
    hitsRestart
  ) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        userid: userId,
        capital: Number(capital),
        totalOper: Number(totalOper),
        totalWin: Number(totalWin),
        payout: Number(payout),
        gale: Number(gale),
        typeMas: Number(typeMas),
        restart: Number(restart),
        target: Number(target),
        modoMasa: Number(modoMasa),
        hitsRestart: Number(hitsRestart),
      }),
    };

    const response = await fetch(`${base_url}/masaparams`, options)
      .then((resp) => resp)
      .catch((error) => console.log("ERROR", error));

    //console.log(response);
    if (response.status === 200) return true;

    return false;
  };

  const handleFollowers = async () => {
    const response = await fetch(`${base_url}/getfollowers?userid=${userId}`)
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    //console.log("Response", response);
    // adicionar função de buscar seguidores
    setSeguidores(response);
  };

  const getCyclesParams = async (brokerID) => {
    const response = await fetch(
      `${base_url}/getcycles?userid=${userId}&brokerid=${brokerID}`
    )
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    //console.log("Response", response);
    setCyclosParams(response);
  };

  const getSorosParams = async (brokerID) => {
    const response = await fetch(
      `${base_url}/getsoros?userid=${userId}&brokerid=${brokerID}`
    )
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    //console.log("Response", response);
    setSorosParams(response);
  };

  const getLossRecParams = async (brokerID) => {
    const response = await fetch(
      `${base_url}/getlossrec?userid=${userId}&brokerid=${brokerID}`
    )
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    //console.log("Response", response);
    setLossRecParams(response);
  };

  const getMetaFollowersData = async () => {
    const response = await fetch(`${base_url}/getmetadata?userid=${userId}`)
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    console.log("Response", response);
    // adicionar função de buscar seguidores
    setMetaFollowersData(response);
  };

  const getMetaTraderData = async () => {
    const response = await fetch(`${base_url}/gettraderdata?userid=${userId}`)
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    //console.log("Response", response);
    setMetaTraderData(response);
  };

  const getTraderInfo = async () => {
    const response = await fetch(`${base_url}/gettraderinfo?userid=${userId}`)
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    //console.log("Response", response);
    setTraderInfo(response);
  };

  const handleRemoveFollow = (userID) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        traderid: userId,
        userid: userID,
      }),
    };

    fetch(`${base_url}/followremove`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const removeMetaFollow = (userID, name) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        traderid: userId,
        userid: userID,
        userName: name,
      }),
    };

    fetch(`${base_url}/removemetafol`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const handleGetTraders = async () => {
    const response = await fetch(`${base_url}/gettraders?userid=${userId}`)
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    // adicionar função de buscar seguidores
    setTradersList(response);
  };

  const getMetaFollowers = async () => {
    const response = await fetch(`${base_url}/getmetafollow?userid=${userId}`)
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error", error);
      });

    // adicionar função de buscar seguidores
    setMetaFollowers(response);
  };

  const handleSaveTraderInfo = ({
    traderEna,
    traderID,
    maxTrades,
    signalType,
    delaysig,
    brokerID,
    minStake,
    maxStake,
    lote,
    acptdemo,
    loteType,
  }) => {
    const data = {
      userid: userId,
      traderEna: Number(traderEna),
      traderID: traderID,
      maxTrades: Number(maxTrades),
      signalType: Number(signalType),
      delaysig: Number(delaysig),
      brokerID: Number(brokerID),
      minStake: Number(minStake),
      maxStake: Number(maxStake),
      lote: Number(lote),
      acptdemo: Number(acptdemo),
      loteType: Number(loteType),
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/tradersave`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendBotCommands = ({ botCmd }) => {
    const data = {
      userid: userId,
      botCmd: Number(botCmd),
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/sendbotcmd`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendCyclesParams = ({
    ciclos,
    ciclosFat,
    ciclosGale,
    ciclosGaleFat,
    brokerID,
  }) => {
    const data = {
      userid: userId,
      ciclos: Number(ciclos),
      ciclosFat: Number(ciclosFat),
      ciclosGale: Number(ciclosGale),
      ciclosGaleFat: Number(ciclosGaleFat),
      brokerID: Number(brokerID),
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/savecycles`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendLossRecParams = ({
    recoverPerc,
    recoverGale,
    recoverGaleFat,
    recoverProfit,
    recoverMode,
    brokerID,
  }) => {
    const data = {
      userid: userId,
      recoverPerc: Number(recoverPerc),
      recoverGale: Number(recoverGale),
      recoverGaleFat: Number(recoverGaleFat),
      recoverProfit: Number(recoverProfit),
      mode: Number(recoverMode),
      brokerID: Number(brokerID),
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/savelossrec`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendSorosParams = ({ soros, sorosGale, sorosPerc, brokerID }) => {
    const data = {
      userid: userId,
      soros: Number(soros),
      sorosgale: Number(sorosGale),
      sorosperc: Number(sorosPerc),
      brokerID: Number(brokerID),
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/savesoros`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendMetaTraderData = ({
    traderEna,
    maxTrades,
    signalType,
    delaysig,
    brokerID,
    anticipation,
  }) => {
    const data = {
      userid: userId,
      traderEna: Number(traderEna),
      maxTrades: Number(maxTrades),
      signalType: Number(signalType),
      delaysig: Number(delaysig),
      brokerID: Number(brokerID),
      anticipation: Number(anticipation),
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/savetraderdata`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendTraderInfo = ({
    copyTraderEn,
    //maxTrades,
    signalType,
    delaysig,
    brokerID,
    candleColor,
    timeframe,
    paresFilter,
    stakeMin,
  }) => {
    const data = {
      userid: userId,
      copyTraderEn: Number(copyTraderEn),
      //maxTrades: Number(maxTrades),
      signalType: Number(signalType),
      delaysig: delaysig,
      brokerID: Number(brokerID),
      candleColor: Number(candleColor),
      timeframe: Number(timeframe),
      stakeMin: Number(stakeMin),
      paresFilter: paresFilter,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/savetraderinfo`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const handleRemoveTrader = (traderID) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        traderid: traderID,
        userid: userId,
      }),
    };

    fetch(`${base_url}/traderemove`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const sendMetaFollowerData = ({
    traderEna,
    traderID,
    maxTrades,
    signalType,
    delaysig,
    brokerID,
    anticipation,
  }) => {
    const data = {
      userid: userId,
      traderid: traderID,
      traderEna: Number(traderEna),
      maxTrades: Number(maxTrades),
      signalType: Number(signalType),
      delaysig: Number(delaysig),
      brokerID: Number(brokerID),
      anticipation: Number(anticipation),
    };

    const options = {
      method: "POST",
      body: JSON.stringify(data),
    };

    fetch(`${base_url}/savemetadata`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  const removeMetaFollowerData = (traderID) => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        traderid: traderID,
        userid: userId,
      }),
    };

    fetch(`${base_url}/removemetadata`, options)
      .then((resp) => resp.data)
      .catch((error) => console.log("ERROR", error));
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const traderId = localStorage.getItem("traderId");
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    //const botIDs = localStorage.getItem("botIDs");

    if (userId && traderId && email && password) {
      setUserId(userId);
      settraderId(traderId);
      setUserEmail(email);
      setUserPass(password);
      console.log("chaves", userId, traderId);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        handleLogin,
        handleGlobalParams,
        handleRecoveryParams,
        handleReaderParams,
        handleMasanielloParams,
        handleSaveOptions,
        handleGetChatList,
        handleSaveParameters,
        handleSaveRecoveryParams,
        saveReaderParams,
        resetReader,
        resetDelete,
        listChannels,
        handleSaveChatList,
        handleUpdateChatList,
        geralConfig,
        setgeralConfig,
        recoveryParams,
        cyclesParams,
        readerConfig,
        masaParams,
        seguidores,
        setSeguidores,
        setMetaFollowers,
        tradersList,
        setTradersList,
        handleFollowers,
        getMetaFollowers,
        getMetaFollowersData,
        getCyclesParams,
        getSorosParams,
        sorosParams,
        getLossRecParams,
        getMetaTraderData,
        getTraderInfo,
        metaFollers,
        metaFollersData,
        metaTraderData,
        traderInfo,
        handleGetTraders,
        handleSaveTraderInfo,
        sendMetaFollowerData,
        sendMetaTraderData,
        sendCyclesParams,
        sendBotCommands,
        sendLossRecParams,
        sendSorosParams,
        lossRecParams,
        sendTraderInfo,
        removeMetaFollowerData,
        handleRemoveTrader,
        handlePhone,
        selectedChannel,
        setSelectedChannel,
        userId,
        setUserId,
        traderId,
        settraderId,
        userEmail,
        setUserEmail,
        userPass,
        setUserPass,
        handleBuyRobot,
        paymentLink,
        setPaymentLink,
        plane,
        setPlane,
        handleChannelRemove,
        handleRemoveFollow,
        removeMetaFollow,
        handleChannConfig,
        handleGetGroups,
        handleSaveTraderID,
        handleSendCustomCycles,
        sendbrokerID,
        sendMetaIDRequest,
        sendCopyRequest,
        sendProxyParams,
        sendMasaParams,
        listGroups,
        setListGroups,
        addChatOnList,
        handleLoggout,
        removeOnList,
        chatList,
        addLoginCode,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
