import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import CopyTrader from "./../pages/CopyTrader";
import CopySignals from "./../pages/CopySignals";
import Configure from "./../pages/Configure";
import GeralConfig from "./../pages/GeralConfig";
import Strategies from "./../pages/Strategies";
import AddPhone from "./../pages/AddPhone";
import ConfigureChannels from "./../pages/ConfigureChannels";
import ChatList from "./../pages/ChatList";
import NotFound from "./../pages/NotFound";
import BuyRobot from "../pages/BuyRobot";
import AuthCode from "../pages/AuthCode";
import Register from "../pages/Register";
import BotCommands from "../pages/BotCommands";
import BotsManager from "../pages/BotsManager";
import BotsPage from "../pages/BotsPage";

import { useUser } from "../contexts/UserContext";
import CopyTraderSinais from "../pages/CopyTraderSinais";
import BrokerConfig from "../pages/BrokerConfig";
import ReaderConfig from "../pages/ReaderConfig";
import ProxyConfig from "../pages/ProxyConfig";
import MasanielloConfig from "../pages/MasanielloConfig";
import ConfigureCycles from "../pages/ConfigureCycles";
import ConfigureSoros from "../pages/ConfigureSoros";
import ConfigureLossRecover from "../pages/ConfigureLossRecover";
import CustomCycles from "../pages/CustomCycles";
import TraderConfig from "../pages/TraderConfig";
import FollowersCopy from "../pages/FollowersCopy";
import FollowConfig from "../pages/FollowConfig";
import CopyRequest from "../pages/CopyRequest";
import ConfigureFollow from "../pages/ConfigureFollow";
import ConfigureMetatrader from "../pages/ConfigureMetatrader";
import ConfigureTraderData from "../pages/ConfigureTraderData";
import ConfigureTraderCopy from "../pages/ConfigureTraderCopy";
import FollowersList from "../pages/FollowersList";
import FollowerMetaConfig from "../pages/FollowerMetaConfig";
import FollowerMetaReq from "../pages/FollowerMetaReq";
import ConfigureMetaFollower from "../pages/ConfigureMetaFollower";

const authRoutes = createBrowserRouter([
  {
    path: "/",
    element: <AuthCode />,
  },
  {
    path: "/cadastrar",
    element: <Register />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Configure />,
  },
  {
    path: "/geralconfig",
    element: <GeralConfig />,
  },
  {
    path: "/strategies",
    element: <Strategies />,
  },
  {
    path: "/brokerconfig",
    element: <BrokerConfig />,
  },
  {
    path: "/configure",
    element: <Configure />,
  },
  {
     path: "/copytrader",
     element: <CopyTrader />,
  },
  {
    path: "/copysignals",
    element: <CopySignals />,
  },
  {
    path: "/reader-params",
    element: <ReaderConfig />,
  },
  {
    path: "/addphone",
    element: <AddPhone />,
  },
  {
    path: "/copy-salas",
    element: <CopyTraderSinais />,
  },
  {
    path: "/configure-channels",
    element: <ConfigureChannels />,
  },
  {
    path: "/channels-list",
    element: <ChatList />,
  },
  {
    path: "/comprar",
    element: <BuyRobot />,
  },
  {
    path: "/auth",
    element: <AuthCode />,
  },
  {
    path: "/botspage",
    element: <BotsPage />,
  },
  {
    path: "/botsmanager",
    element: <BotsManager />,
  },
  {
    path: "/botcommands",
    element: <BotCommands />,
  },
  {
    path: "/configproxy",
    element: <ProxyConfig />,
  },
  {
    path: "/config-masaniello",
    element: <MasanielloConfig />,
  },
  {
    path: "/config-cycles",
    element: <ConfigureCycles />,
  },
  {
    path: "/config-soros",
    element: <ConfigureSoros />,
  },
  {
    path: "/config-recovery",
    element: <ConfigureLossRecover />,
  },
  {
    path: "/config-customcycles",
    element: <CustomCycles />,
  },
  {
    path: "/traderconfig",
    element: <TraderConfig />,
  },
  {
    path: "/followerscopy",
    element: <FollowersCopy />,
  },
  {
    path: "/followconfig",
    element: <FollowConfig />,
  },
  {
    path: "/configfollow",
    element: <ConfigureFollow />,
  },
  {
    path: "/copyrequest",
    element: <CopyRequest />,
  },
  {
    path: "/configmetatrader",
    element: <ConfigureMetatrader />,
  },
  {
    path: "/configtradercopy",
    element: <ConfigureTraderCopy />,
  },
  {
    path: "/tradermetaconfig",
    element: <ConfigureTraderData />,
  },
  {
    path: "/followerslist",
    element: <FollowersList />,
  },
  {
    path: "/followermetaconfig",
    element: <FollowerMetaConfig />,
  },
  {
    path: "/followermetareq",
    element: <FollowerMetaReq />,
  },
  {
    path: "/configmetafollower",
    element: <ConfigureMetaFollower />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default function MainRoutes() {
  const { userEmail, userPass } = useUser();

  return (
    <RouterProvider router={userEmail && userPass ? appRoutes : authRoutes} />
  );
}
