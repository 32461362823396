import React, { useEffect, useState } from "react";
import { Linking, Text } from "react-native";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import {
  Button,
  Container,
  Divider,
  SubTitle,
  Title,
  TitleRed,
} from "./styles";

import { useUser } from "./../../contexts/UserContext";
import Translator from "../../components/i18nComponents/Translator";

import { useTranslation } from "react-i18next";

export default function AuthCode() {

  const {t} = useTranslation()

  const { handleLogin, alert, setAlert, handleLoggout } = useUser();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleinsert = async () => {
    if (!email || !password) setAlert("Favor insira os dados corretamente!!");
    handleLogin(email, password);
  };

  const handleUserLoggout = async () => {
    await handleLoggout();
  };

  /*
  useEffect(() => {

    const user = localStorage.getItem("email");
    const pass = localStorage.getItem("password");
    if (user && pass) {
      setEmail(user);
      setPassword(pass);
    }

  }, []);
  */

  return (
    <Container>
      <Title>Login</Title>
      <SubTitle><Translator path="paginas.authCode.Subtitle"/></SubTitle>
      <Divider />
      
      <>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
        >
          <TextField
            required
            fullWidth
            sx={{ marginY: "15px" }}
            type={"text"}
            autoComplete='off'
            onChange={(e) => setEmail(e.target.value.trim())}
            variant="filled"
          />
          <TextField
            required
            fullWidth
            sx={{ marginY: "15px" }}
            type={"password"}
            autoComplete='off'
            onChange={(e) => setPassword(e.target.value.trim())}
            variant="filled"
          />
        </Box>

        <Button onClick={handleinsert}>Login</Button>
        {email && password && (
          <Button onClick={handleUserLoggout}>Loggout</Button>
        )}

        <Text style={{color: 'red'}}
        onPress={() => Linking.openURL('/cadastrar')}>
        <Translator path="paginas.authCode.linkRegistro"/>
        </Text>
      </>

      {alert && <TitleRed>{alert}</TitleRed>}

    </Container>
  );
}
