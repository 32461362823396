import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button    from "@mui/material/Button";
import Box       from "@mui/system/Box";
import Snackbar from "../../components/Snackbar";
import {
  Button as ButtonStyle,
  ButtonCSS,
  Container,
  Divider,
  SubTitle,
  Title,
  TitleRed,
} from "./styles";

import {useTranslation} from 'react-i18next'

import { useUser } from "./../../contexts/UserContext";
import { register } from "../../services/api";
import { errors_messages, initialState } from "./config";
import { useNavigate } from "react-router";
import Translator from "../../components/i18nComponents/Translator";

export default function Register() {

    const {t} = useTranslation()
    const { alert2, setAlert, handleLoggout } = useUser();
    const [ state , setState  ] = useState(initialState);
    const [ error, setError   ] = useState(true)
    const navigate = useNavigate()
    const [saveStateOpen, setSaveStateOpen] = useState(false);

    const handleinsert = async () => {
        
        try {
            
            const { email, password, userid, traderid } = state

            if (!email || !password || !userid)
                setAlert(t('paginas.register.messageErrorDados'));
            
            const request = {
                email, 
                password, 
                userid, 
                traderid: userid  
            }

            const response = await register(request);
            const data     = await response.json()
            //console.log(data);
            if (response.status === 200) {

                localStorage.setItem("userId", data.userid);
                localStorage.setItem("traderId", data.traderid);
                alert(t('paginas.register.messageCadastroOk'));
                navigate("/");

            } else if (data.msg == "user_exist" ) {
                alert(t('paginas.register.messageEmailCadastrado'));
            } else if (data.msg == "bot_exist" ) {
                alert(t('paginas.register.messageIdCadastrado'));
            }

        } catch (error) {
            alert(t('paginas.register.messageErroCadastro'));
            console.log(error, "ERROR")    
        }
    };

    const handleChange = (e) => {
        const {target: { id, value }} = e
        setState( prevState => ({ ...prevState,  [id]: value }))
    }

    const handleUserLoggout = async () => {
        await handleLoggout();
    };

    const handleStateErrors = (id, message) => {
        setState( prevState => (
            { ...prevState, 
                errors:  
                    { 
                        ...prevState.errors, 
                        [id]: message 
                    }
            }
        ))
    }

    const handleBlur = (e) => {
        
        // const message = e.target.value !== state.password ? 'Senha de confirmação diferente da digitada' : " "

        // handleStateErrors(e.target.id, message)      
        
        setErrorsMessages(e.target.id)
        
        //e.target.value !== state.password && setAlert("Senha diferente da digitada!")
    };

    useEffect(() => {
        //
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      var arr = windowUrl.split('?');
      if (arr.length > 1 && arr[1] !== '') {
        var userid = params.get('userid');
        state.userid = userid;
        //console.log("traderid = ", );
        var vendid = params.get('sellerid');
        if (vendid != userid) {
          localStorage.setItem("sellerID", vendid);
          console.log("sellerid = ", params.get('sellerid'));
        }
      }
    }, []);

    const setErrorsMessages = (key) => {
        const message = errors_messages(state)[key]
        handleStateErrors(key, message)
    }

    const checkErrors = useCallback( () => {
        
        const keys     = Object.keys(state.errors)
        
        const hasError = keys.some( key => Boolean(state.errors[key].trim()) )

        setError(hasError)

    }, [state.errors, setError])

    useEffect( () => {
        checkErrors()
    }, [ checkErrors ])

    const inputsNotEmpty = Boolean(state.email.trim()) && Boolean(state.password.trim()) && Boolean(state.confirmPassword.trim()) && Boolean(state.userid.trim())  


    return (
        <Container>
        <Title><Translator path="paginas.register.titlePagina"/></Title>
        <SubTitle><Translator path="paginas.register.subtitlePagina"/></SubTitle>
        <Divider />
        

        <>
            <Box
                component="form"
                sx={{
                    maxWidth: "100%",
                    "& > .MuiFormControl-root" : { margin: "2px 0 0 0 !important" }
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    id="email"
                    required
                    fullWidth
                    sx={{ marginY: "15px" }}
                    label={"Email:"}
                    type="email"
                    value={state.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="filled"
                    helperText={state.errors.email}
                    error={Boolean(state.errors.email.trim())}
                />
                <TextField
                    id="password"
                    required
                    fullWidth
                    sx={{ marginY: "15px" }}
                    label={t('senha')}
                    value={state.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="filled"
                    helperText={state.errors.password}
                    error={Boolean(state.errors.password.trim())}
                    
                />
                <TextField
                    id="confirmPassword"
                    required
                    fullWidth
                    sx={{ marginY: "15px" }}
                    label={t("paginas.register.textFields.confirmeSenha")}
                    value={state.confirmPassword}
                    onChange={handleChange}
                    variant="filled"
                    onBlur={handleBlur}
                    helperText={state.errors.confirmPassword}
                    error={Boolean(state.errors.confirmPassword.trim())}
                />
                <TextField
                    id="userid"
                    required
                    fullWidth
                    sx={{ marginY: "15px" }}
                    label={t("paginas.register.textFields.userId")}
                    value={state.userid}
                    type="number"
                />
               
            </Box>

            <Button sx={{
                ...ButtonCSS, 
                    "&:hover": {
                        color: "#000", 
                        background: 'transparent' 
                    }, 
                    background: 'transparent', 
                    color: '#000', 
                    width: '100%'
                }} 
                disabled={error || !inputsNotEmpty } 
                onClick={handleinsert} 
                variant={'contained'}>
                    <Translator path="paginas.register.buttonCadastrar"/>
            </Button>
            
            <Snackbar
                type="success"
                open={saveStateOpen}
                onClose={() => setSaveStateOpen(false)}
            >
                <Translator path="paginas.register.snackbarCadastroOk"/>
            </Snackbar>
        </>
        {alert2 && <TitleRed>{alert2}</TitleRed>}
        </Container>
    );
}
