import React, { useEffect, useState } from "react";

import {
  Container,
  Title,
  Divider,
  Button,
} from "./styles";

import Translator from "../../components/i18nComponents/Translator";

export default function BotsPage() {

  return (
    <Container>

      <Title><Translator path="paginas.Botspage.Title"/></Title>
      <Divider />

      <Button>
        <a href="/botcommands"><Translator path="paginas.Botspage.linkBotcommands"/></a>
      </Button>

      <Button>
        <a href="/botsmanager"><Translator path="paginas.Botspage.linkBotManager"/></a>
      </Button>

      </Container>
  );
}
