import React, { useEffect } from "react";

import { ChannelContainer, Container, Divider, Title } from "./styles";
import { useUser } from "../../contexts/UserContext";
import FollowerMetaConfig from "../FollowerMetaConfig";
import Translator from "../../components/i18nComponents/Translator";

export default function ConfigureMetaFollower() {
  const { metaFollersData, getMetaFollowersData } = useUser();

  useEffect(() => {
    getMetaFollowersData();
  }, []);

  return (
    <Container>
      <Title><Translator path="paginas.copyTrader.Title"/></Title>
      <Divider />

      <ChannelContainer>
        {metaFollersData.map((trader, index) => (
          <FollowerMetaConfig trader={trader} key={index} />
        ))}
      </ChannelContainer>
    </Container>
  );
}
