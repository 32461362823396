// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    // Same value used in settings (defaultNS)

    // Universal Values
    buttonSalvar: "Save",
    snackbarSucess: "Saved",
    buttonEnviar: "Send",
    senha: "Password",
    buttonSendMetaReq: "Send request",

    // Components
    componentes: {
      MiniDrawer: {
        textUser: "User",
        textRobo: "Robot",

        linksConfig: "Settings",
        linksComprarRobo: "Buy Robot",
      },

      // Alert Component
      AlertsComponente: {
        // example: "example text"
      },
      // End Alert Component

      // ChannelCard Component
      ChannelCardComponente: {
        // Texts in the component
        TextoSalvo: "Saved",
        ButtonRemover: "Remove",
        textCheckox: "Enable",
        accDemoCheckox: "Accept demo",
        sendTraderCheckox: "Main Bot",

        // Alert text at the end of else
        handleSaveElse: "Only traders can remove rooms",

        // Label texts
        TextFieldMultiplicador: "Multiplier",
        TextFieldEntrada: "Trade Value:",
        TextFieldFiltro: "Filter:",
        TextFieldIgnorar: "Ignore:",

        // Texts of the SinalTipo select
        SelectSinalTipo: {
          // Label
          labelSinalTipo: "signalType",
          // Items
          MenuItems: {
            MenuItem1: "Schedule",
            MenuItem2: "Lists",
            MenuItem3: "Immediate",
            MenuItem4: "Immediate previous",
            MenuItem5: "Immediate subsequent",
            MenuItem6: "Immediate next candle",
            MenuItem7: "End of candle",
            MenuItem8: "Full time",
            MenuItem9: "Interval",
          },
        },

        candleColorTipo: {
          // Label
          labelCandleColor: "candleColors",
          // Itens
          MenuItens: {
            MenuItem1: "Disabled",
            MenuItem2: "Reversals",
            MenuItem3: "Trend",
          },
        },

        // Texts of the Corretora select
        SelectCorretora: {
          // Label
          labelCorretora: "Broker",
          // Items
          MenuItems: {
            MenuItem1: "All",
            MenuItem2: "IQ",
            MenuItem3: "Deriv",
            MenuItem4: "DayProfit",
            MenuItem5: "CapitalBear",
            MenuItem6: "Quotex",
            MenuItem7: "Pocket",
            MenuItem8: "Mango",
            MenuItem9: "Exnova",
            MenuItem10: "Binomo",
            MenuItem11: "Stockity",
            MenuItem12: "Binolla",
            MenuItem13: "Olymp 1",
            MenuItem14: "Olymp 2",
            MenuItem15: "Bullex",
          },
        },
      },
      // End ChannelCard Component -----

      // ReaderParams
      ReaderParams: {
        textFusoHorario: "Time zone:",
        textOrdemSimultaneas: "Simultaneous Orders:",
        textFiltroHorarioInicio: "Filter start time 1:",
        textFiltroHorarioFinal: "Filter end time 1:",
        textFiltroHorarioInicio2: "Filter start time 2:",
        textFiltroHorarioFinal2: "Filter end time 2:",
        textFiltroHorarioInicio3: "Filter start time 3:",
        textFiltroHorarioFinal3: "Filter end time 3:",
        textFiltroHorarioReset: "Reset bot time:",
        textFiltroHorarioZeraPl: "Clear score time:",
      },
      // End ReaderParams

      // GlobalParamsScreen
      globalParamsScreen: {
        formLabelConta: "Account type:",
        radioGroupConta: {
          ContaReal: "Real account",
          ContaDemo: "Demo account",
        },

        formLabelOpcao: "Option type",
        radioGroupOpcao: {
          Binarias: "Binary",
          Digitais: "Digital",
          Ambas: "Biggest Payout",
          BinDig: "Binary/Digital",
        },

        formLoginMetodo: "Login Method",
        formStrategySel: "Strategy",
        formTypeOperSel: "Operation type",

        formLabelEntrada: "Entry type:",
        radioGroupEntrada: {
          ValorFixo: "Fixed amount $",
          Percentual: "Bankroll percentage",
          CopyPerc: "Copy trader %",
        },

        textEntrada: "Trade Value:",

        textGaleNum: "Level of Martingale",
        textGale: "Multiplier",

        textWinStop: "Win/Stop type:",
        MenuItemWinStop: {
          ValorFixo: "Fixed amount $",
          Percentual: "Bankroll percentage",
          Diferencial1: "Delta loss",
          Diferencial2: "Delta win+loss",
          Quant: "Quantity",
          Movel: "Floating",
        },
      },
      // End GlobalParamsScreen

      // LossRecoverScreen
      LossRecoverScreen: {
        textCiclos: "Level Cycles:",
        textFatorCiclos: "Cycle Multiplier:",
        textGaleNum: "Martingale Level:",
        textFatorGale: "Martingale multiplier",
        textBancaMinima: "Minimum Bankroll:",
        textNumeroHits: "Number of Hits",
        textPosLossEntrada: "PosLoss Entry:",
        textPosLossFator: "PosLoss multiplier:",
        textSoros: "Level Soros:",
        textSorosGale: "Martingale:",
        textSorosPercentual: "Soros Percentage:",
        textRecoverLoss: "Recovery Factor",
        textRecoverProfit: "Restart Profit",
        textRecoverMode: "Mode",
        textRecoverMode1: "Conservative",
        textRecoverMode2: "Moderate",
        textRecoverMode3: "Aggressive",
      },
      // End LossRecoverScreen
    },
    // End Components -------

    // Pages
    paginas: {
      // Register
      register: {
        // Alert
        messageErrorDados: "Please enter the data correctly!!",
        messageCadastroOk: "Registration successful!",
        messageEmailCadastrado: "Email already registered",
        messageIdCadastrado: "ID already registered",
        messageErroCadastro: "Error in registration",

        // Texts on the page
        titlePagina: "Registration Screen",
        subtitlePagina: "Enter registration data!!",

        // Label TextField
        textFields: {
          senha: "password:",
          confirmeSenha: "Confirm your password:",
          userId: "Telegram ID",
        },

        // Register button
        buttonCadastrar: "Register",

        // Snackbar
        snackbarCadastroOk: "Registration Successful!",

        // Configuration texts
        config: {
          email: "The email field cannot be empty!",
          password: "The password field cannot be empty!",
          confirmPassword:
            "The password confirmation field is different from the one entered",
          confirmPasswordNull:
            "The password confirmation field cannot be empty!",
          userId: "The Telegram ID field cannot be empty!",
        },
      },
      // End register ------------

      // Reader config
      readerConfig: {
        titlePagina: "Reader Parameters",

        // The rest of the page is in the ReaderParams component
      },
      // End Reader config ------------

      // ProxyConfig
      proxyConfig: {
        Title: "Proxy Parameters",
        textProxyendereco: "Proxy address:",
        textProxyPorta: "Proxy Port:",
        textPassword: "Password:",
      },
      // End ProxyConfig

      // NotFound (404)
      notFound: {
        Title: "Page not found",
        buttonHome: "Back to Home",
      },
      // End NotFound

      // MasanielloConfig
      masanielloConfig: {
        messageSaved: "Parameters saved",

        Title: "Masaniello Management",

        textCapital: "Capital:",
        textTotalOperacoes: "Total Operations:",
        textNumeroVitorias: "Number of Wins:",
        textValorAlvo: "Profit Restart:",
        textValorHit: "Hits to Restart:",

        textTipo: "Type:",

        MenuItensTipo: {
          Desativado: "Disabled",
          Normal: "Normal",
          Progressive: "Progressive",
        },

        textModo: "Mode:",

        MenuItensModo: {
          UmSinal: "Single signal",
          MultiploSinais: "Multiple signals",
        },

        CheckboxReiniciar: "Restart if capital lost",
      },
      // End MasanielloConfig

      // GeralConfig
      geralConfig: {
        titleGerais: "General Parameters",
        titleRecuperacao: "Recovery Parameters",

        // The rest of the page is in the GlobalParamsScreen component
        // The rest of the page is in the LossRecoverScreen component
      },
      // End GeralConfig

      addPhone: {
        Title: "Connect to Telegram",

        textAddTelefone: "Add Phone:",
        textAddTelegram: "Telegram Code:",
        textSenhaTelegram: "Telegram Password:",
      }, // End addPhone

      // AuthCode
      authCode: {
        Subtitle: "Enter email and password",

        labelSenha: "Password",

        linkRegistro: "Register Here",
      }, // End authCode

      // BrokerConfig
      BrokerConfig: {
        Title: "Brokers",
      }, // End BrokerConfig

      // BuyRobot
      buyRobot: {
        textTipoDePlano: "Plan Type",
        // days
        dias: {
          plano1: "1 bot 7 days: R$40",
          plano2: "1 bot 15 days: R$60",
          plano3: "1 bot 30 days: R$80",
          plano4: "1 bot 90 days: R$180",
          plano5: "1 bot 180 days: R$350",
          plano6: "2 bots 30 days: R$100",
          plano7: "2 bots 90 days = R$240",
          plano8: "3 bots 30 days = R$110",
          plano9: "3 bots 90 days = R$270",
          plano10: "4 bots 30 days = R$120",
          plano11: "4 bots 90 days = R$300",
        },

        Title: "Payment",

        textMetodo: "Method",
        metodoItens: {
          MercadoPago: "Mercado Pago",
          Hotmart: "Hotmart",
          Pix: "Pix",
        },

        buttonGerarLink: "Generate Link",

        linkPagamento: "Payment Link",

        // label
        labelIdTelegram: "Your Telegram ID:",
        labelCPF: "CPF (Without dots or dashes):",
        labelVendedor: "Seller ID:",

        // Alert
        messageDadosIncorretos: "Please enter the data correctly!",
        messageCpf: "Please enter the CPF",
      }, // End BuyRobot

      // ChatList
      channelList: {
        messageAtualizando: "Updating... please wait a few seconds",
        messageApenasTrader: "Only traders can update/save",

        title: "Groups and Channels",

        textAguarde: "Please wait a few seconds!",
        buttonListar: "List",
      },
      // End ChatList

      // Configure
      configure: {
        Title: "Robot Configuration",

        linkCorretora: "Brokers",
        linkGeral: "General Configuration",
        linkCopySignals: "Copy Signals",
        linkCopyTrade: "Copy Trader",
        linkMasaniello: "Masaniello",
        linkProxy: "Configure Proxy",
        linkComprarRobo: "Buy the Robot",
        linkSuporte: "Suport sonnybot",
        linkCopyMetatrader: "Metatrader copy",

        linkBotsPage: "Bots",
      },
      // End Configure

      Botspage: {
        Title: "Bots",

        linkBotcommands: "Bot commands",
        linkBotManager: "Gerenciar bots",
      },

      Strategies: {
        Title: "Strategies",

        linkMasaniello: "Masaniello",
        linkCiclos: "Cycles",
        linkSoros: "Soros",
        linkRecoverPerc: "Loss Recovery",
        linkCustomCycle: "Custom",
      },

      CustomCycles: {
        subTitle: "Custom",
      },

      // ConfigureChannels
      configureChannels: {
        Title: "Configure Rooms",
      },
      // End ConfigureChannels

      // CopySignals
      copySignals: {
        Title: "Copy Signals Configuration",

        linkAddPhone: "Add Phone",
        linkChannelList: "Add Rooms",
        linkConfigSala: "Configure Rooms",
        linkParametros: "Reader Parameters",
        linkCopyTrader: "Copy Rooms",

        buttonReset: "Reset",
        buttonDeletar: "Delete",
      },
      // End CopySignals

      // CopyTrader
      copyTrader: {
        TitleMain: "Copytrader Configuration",
        TitleTrader: "Trader Configuration",
        TitleSeguidor: "Follower Configuration",
        TitleMetaReq: "Follow a Trader",
        Title: "Follower Configuration",

        LoteType: "Entry type",
        LoteType1: "Entrada fixa",
        LoteType2: "Copy trade",

        TitleMetaCopy: "MetaCopy Configuration",
        TitleMetaTrader: "Metatrader Configuration",
        TitleTraderFollowers: "Followers List",

        textHabilitar: "Enable Copy Trader",
        textListaSeguidores: "Follower List",

        textSeguidor: "Follower",
        buttonRemover: "Remove",
        buttonDesativar: "Deactivate",

        textOrdemSimultaneas: "Ordens Simultâneas:",
        textMinValue: "Entrada Minima:",
        textMaxValue: "Entrada Máxima:",
        maturity: "Maturity",
        typeOfBot: "Type of bot",
        actions: "Actions",
      },
      // End CopyTrader

      // CopyTraderSignals
      CopyTraderSignals: {
        subTitle: "Copy Trader Configuration",
        subTitle2: "Copy rooms Configuration",
        subTitleMetaReq: "Send MetaCopy Request",
        subTitleCopyReq: "Send CopyTrader Request",

        textIdTrader: "Trader ID:",
        textUserName: "Your Name",
      },

      // End CopyTraderSignals
    },
    // End Pages
  },
};
