import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, MenuItem, Select, TextField } from "@mui/material";

import { SubContainer, Divider } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import Translator from "../i18nComponents/Translator";

export default function ReaderParams() {

  const { handleReaderParams, saveReaderParams, readerConfig, userId } =
    useUser();

  const [timezone, setTimezone] = useState(null);
  const [maxTrades, setMaxTrades] = useState(null);
  //
  const [hourStart, setHourStart] = useState(null);
  const [hourEnd, setHourEnd] = useState(null);
  //
  const [hourStart2, setHourStart2] = useState(null);
  const [hourEnd2, setHourEnd2] = useState(null);
  //
  const [hourStart3, setHourStart3] = useState(null);
  const [hourEnd3, setHourEnd3] = useState(null);
  //
  const [hourReset, setHourReset] = useState(null);
  const [hourClearScore, setHourZeraPlacar] = useState(null);
  const [someStateOpen, setSomeStateOpen] = useState(false);

  const handleSaveParameter = useCallback(async () => {
    //
    setSomeStateOpen((prevState) => !prevState);

    if (maxTrades < 1) {
        alert("Limite de Ordens precisa ser 1 ou maior");
        return;
    }
    //
    await saveReaderParams(timezone, maxTrades, hourStart, hourEnd, hourStart2, hourEnd2,
      hourStart3, hourEnd3, hourReset, hourClearScore);
  });

  const handleSetReadParams = (data) => {
    setTimezone(data.timerzone);
    setMaxTrades(data.maxtrades);
    setHourStart(data.hourStart);
    setHourEnd(data.hourEnd);
    setHourStart2(data.hourStart2);
    setHourEnd2(data.hourEnd2);
    setHourStart3(data.hourStart3);
    setHourEnd3(data.hourEnd3);
    setHourReset(data.hourReset);
    setHourZeraPlacar(data.hourClearScore);
  };

  useEffect(() => {
    if (readerConfig) handleSetReadParams(readerConfig);
  }, [readerConfig]);

  useEffect(() => {
    if (userId) handleReaderParams();
  }, [userId]);

  /*
  useEffect(() => {
    const get = () => readerConfig ?
      handleSetReadParams(readerConfig) : handleReaderParams()

    if (readerConfig || userId) {
      get();
    }
  }, [readerConfig, userId]);
  */

  return (
    <>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFusoHorario"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={timezone}
            onChange={(e) => {
              setTimezone(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textOrdemSimultaneas"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="number"
            value={maxTrades}
            onChange={(e) => {
              setMaxTrades(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Divider />

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioInicio"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourStart}
            onChange={(e) => {
              setHourStart(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioFinal"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourEnd}
            onChange={(e) => {
              setHourEnd(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioInicio2"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourStart2}
            onChange={(e) => {
              setHourStart2(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioFinal2"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourEnd2}
            onChange={(e) => {
              setHourEnd2(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioInicio3"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourStart3}
            onChange={(e) => {
              setHourStart3(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioFinal3"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourEnd3}
            onChange={(e) => {
              setHourEnd3(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Divider />

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioReset"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourReset}
            onChange={(e) => {
              setHourReset(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.ReaderParams.textFiltroHorarioZeraPl"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            type="time"
            value={hourClearScore}
            onChange={(e) => {
              setHourZeraPlacar(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Snackbar
        type="success"
        open={someStateOpen}
        autoHideDuration={2000}
        onClose={() => setSomeStateOpen(false)}
      >
       <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParameter()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>
    </>
  );
}
