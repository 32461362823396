import styled from "styled-components";

export const AppContainer = styled.div`
  text-align: center;
  background-color: #2b2b2b;
  color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 85%;
`;

export const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin: 10px 0;
`;

export const Table = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
  width: calc(max-content - 24px);

  @media (max-width: 768px) {
    width: calc(max-content - 24px);
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const TableHeader = styled.th`
  border: 1px solid #444;
  padding: 10px;
  background-color: #333;

  //   @media (max-width: 768px) {
  //     display: none;
  //   }
`;

export const TableHeaderTr = styled.tr`
  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;

export const TableCell = styled.td`
  border: 1px solid #444;
  padding: 10px;
  text-align: center;

  @media (max-width: 768px) {
    display: block;
    border: none;
    padding: 5px;
  }
`;

export const TableRow = styled.tr`
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const TableCol = styled.td`
  border: 1px solid #444;
  padding: 10px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 5px;
    min-width: 70px;
  }
`;

export const TableCellLabel = styled.td`
  @media (max-width: 768px) {
    display: block;
    font-weight: bold;
    background-color: #444;
    padding: 5px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  text-align: center;
  background-color: #333;
  color: #fff;
  border: none;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: calc(100% - 10px);
    margin: 5px 0;
  }
`;

export const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2980b9;
  }
`;
