import React, { useContext, useEffect, useState } from "react";

import { Container, Title, Divider, Button } from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Translator from "../../components/i18nComponents/Translator";
import { UserContext } from "../../contexts/UserContext";
import { api } from "../../services/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const base_url = process.env.REACT_APP_BASE_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateBot({ open, handleClose, refetchBots, bots }) {
  const [option, setOption] = useState("");
  const [botId, setBotId] = useState("");
  const [botIdVirtual, setBotIdVirtual] = useState("");

  const { userEmail, userId } = useContext(UserContext);

  const generateNewId = (originalId) => {
    const botsWithOriginalId = bots.filter(({ userid }) =>
      userid.includes(originalId)
    );

    if (botsWithOriginalId.length > 1) {
      const lastElement = botsWithOriginalId[botsWithOriginalId.length - 1];
      const lastElementUserId = lastElement.userid;

      const firstUserIdConverted = lastElementUserId.replace(
        lastElementUserId[0],
        parseInt(lastElementUserId[0]) + 1
      );

      return firstUserIdConverted;
    }

    const userIdConverted = `1${botsWithOriginalId[0].userid}`;
    return userIdConverted;
  };

  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const createBot = async () => {
    const create = {
      userid: userId,
      botid: option === "Virtual" ? generateNewId(botIdVirtual) : botId,
      virtual: option === "Virtual",
      email: userEmail,
    };

    const options = {
      method: "POST",
      body: JSON.stringify(create),
    };

    await fetch(`${base_url}/addnewbot`, options).then(() => {
      handleClose();
      refetchBots();
    });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Criar Bot
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="option-select-label">Opção</InputLabel>
          <Select
            labelId="option-select-label"
            value={option}
            label="Opção"
            onChange={handleOptionChange}
          >
            <MenuItem value="">Selecione uma opção</MenuItem>
            <MenuItem value="Telegram">Telegram</MenuItem>
            <MenuItem value="Virtual">Virtual</MenuItem>
          </Select>
        </FormControl>
        {option === "Telegram" && (
          <TextField
            fullWidth
            sx={{ mt: 2 }}
            label="User ID"
            value={botId}
            onChange={(e) => setBotId(e.target.value)}
          />
        )}
        {option === "Virtual" && (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>User ID</InputLabel>
            <Select
              value={botIdVirtual}
              onChange={(e) => setBotIdVirtual(e.target.value)}
              label="User ID"
            >
              {bots
                ?.filter((bot) => !bot.virtual)
                .map((bot) => (
                  <MenuItem key={bot.userid} value={bot.userid}>
                    {bot.userid}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            if (botId.length > 1 || botIdVirtual.length > 1) {
              createBot();
            }
          }}
        >
          Criar
        </Button>
        <Button fullWidth variant="contained" onClick={handleClose}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
}

function ModalDeleteBot({ open, handleClose, deleteBot, selectedId }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Você tem certeza que deseja excluir?
        </Typography>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            deleteBot(selectedId);
            handleClose();
          }}
        >
          Confirmar
        </Button>
        <Button fullWidth variant="contained" onClick={handleClose}>
          Cancelar
        </Button>
      </Box>
    </Modal>
  );
}

export default function BotsManager() {
  const { userId } = useContext(UserContext);
  const [bots, setBots] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const fetchBots = async () => {
    return api
      .get("/getbots", { params: { userid: userId } })
      .then((response) => setBots(response.data))
      .catch((err) => console.log("ERROR", err));
  };
  useEffect(() => {
    fetchBots();
  }, []);

  const deleteBot = async (id) => {
    return api
      .get("/botremove", { params: { userid: id } })
      .then(() => {
        fetchBots();
      })
      .catch((err) => console.log("ERROR", err));
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCloseModalDelete = () => {
    setIsOpenDeleteModal(false);
  };

  return (
    <Container>
      <Title>
        <Translator path="paginas.copyTrader.TitleMain" />
      </Title>
      <Divider />
      <Button onClick={() => setIsOpen(true)}>Criar Bot</Button>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Email</TableCell>
              <TableCell sx={{ color: "white" }}>User ID</TableCell>
              <TableCell sx={{ color: "white" }}>
                <Translator path="paginas.copyTrader.maturity" />
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <Translator path="paginas.copyTrader.typeOfBot" />
              </TableCell>
              <TableCell sx={{ color: "white" }}>
                <Translator path="paginas.copyTrader.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bots?.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ color: "white" }}>{row.email}</TableCell>
                  <TableCell sx={{ color: "white" }}>{row.userid}</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {formatUnixTimestamp(row.botexp)}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {row.virtual ? "Virtual" : "Telegram"}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={true}
                      aria-label="delete"
                      onClick={() => {
                        setIsOpenDeleteModal(true);
                        setSelectedId(row.userid);
                      }}
                    >
                      <DeleteIcon sx={{ cursor: "pointer" }} />
                    </IconButton>
                    <IconButton aria-label="renovar">
                      <a
                        href="https://sonnybot.com/comprar"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <CachedIcon
                          sx={{ cursor: "pointer", color: "white" }}
                        />
                      </a>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CreateBot
        open={isOpen}
        handleClose={handleClose}
        refetchBots={fetchBots}
        bots={bots}
      />
      <ModalDeleteBot
        open={isOpenDeleteModal}
        handleClose={handleCloseModalDelete}
        deleteBot={deleteBot}
        selectedId={selectedId}
      />
    </Container>
  );
}
