import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, TextField } from "@mui/material";

import { SubContainer, Divider } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import Translator from "../../components/i18nComponents/Translator";

export default function ConfigureSoros() {

  const { sendSorosParams, getSorosParams, sorosParams, userId } = useUser();

  const [soros, setSoros] = useState(null);
  const [sorosGale, setSorosGale] = useState(null);
  const [sorosPerc, setSorosPerc] = useState(null);
  const [brokerID, setBrokerID] = useState(null);
  const [saveStateOpen, setSaveStateOpen] = useState(false);
  //

  const handleSaveParamsRecv = useCallback(async () => {
    //
    if (soros < 0 || sorosGale < 0 || sorosPerc <= 0) {
        alert("Parametros invalidos");
        return;
    }
    //
    if (sorosPerc > 100) {
        alert("Soros Percentual apenas entre 1 e 100");
        return;
    }
    //
    setSaveStateOpen((prevState) => !prevState);
    //
    //
    await sendSorosParams({
      soros,
      sorosGale,
      sorosPerc,
      brokerID,
    });
  }, [
    sendSorosParams,
      soros,
      sorosGale,
      sorosPerc,
      brokerID,
  ]);

  const handleSetRecoveryParams = (data) => {
    setSoros(data.soros);
    setSorosGale(data.sorosgale);
    setSorosPerc(data.sorosperc);
  };

  useEffect(() => {
    if (sorosParams)  {
      handleSetRecoveryParams(sorosParams);
    }
  }, [sorosParams]);

  useEffect(() => {
    if (userId) {
      var brokerIDX = localStorage.getItem("brokerID");
      if (brokerIDX === null) {
        brokerIDX = 1;
      }
      setBrokerID(brokerIDX);
      getSorosParams(brokerIDX);
    }
  }, [userId]);


  return (
    <>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textSoros"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={soros}
            type="number"
            onChange={(e) => {
              setSoros(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.globalParamsScreen.textGaleNum"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={sorosGale}
            type="number"
            onChange={(e) => {
              setSorosGale(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textSorosPercentual"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={sorosPerc}
            type="number"
            onChange={(e) => {
              setSorosPerc(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Divider />

      <Snackbar
        type="success"
        open={saveStateOpen}
        autoHideDuration={2000}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParamsRecv()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>

    </>
  );
}
