import React, { useCallback, useState } from "react";

import FormControl from "@mui/material/FormControl";
import { Button, MenuItem, Select } from "@mui/material";

import { Container } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import { SubTitle } from "../../pages/CopyTrader/styles";
import Translator from "../../components/i18nComponents/Translator";


export default function BotCommands() {

  //const {t} = useTranslation()

  const { sendBotCommands } = useUser();

  const [botCmd, setBotCmd] = useState(null);
  const [saveStateOpen, setSaveStateOpen] = useState(false);

  const handleSaveParams = useCallback(async () => {
    //
    setSaveStateOpen((prevState) => !prevState);
    //
    if (!botCmd) {
        alert("Parametros invalidos");
        return;
    }
    //
    await sendBotCommands({
      botCmd,
    });
  }, [
    sendBotCommands,
      botCmd,
  ]);

  return (
    <>

      <Container>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle>Comandos do bot:</SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={botCmd}
            onChange={(e) => setBotCmd(e.target.value)}
            required
          >
          <MenuItem value={1}>Ligar</MenuItem>
          <MenuItem value={2}>Desligar</MenuItem>
          <MenuItem value={3}>Status</MenuItem>
          <MenuItem value={4}>Reiniciar</MenuItem>
          <MenuItem value={5}>Placar</MenuItem>
          <MenuItem value={6}>Info</MenuItem>
          <MenuItem value={7}>Galeprice</MenuItem>
          <MenuItem value={8}>TipoConta</MenuItem>
          <MenuItem value={9}>Modocopy</MenuItem>
          <MenuItem value={10}>ZeraPlacar</MenuItem>
          <MenuItem value={11}>ZeraMasaniello</MenuItem>
          <MenuItem value={12}>ZeraCiclo</MenuItem>
          <MenuItem value={13}>ZeraRecuperação</MenuItem>
          <MenuItem value={14}>UpdateBot</MenuItem>
          <MenuItem value={15}>LigaCopy</MenuItem>

          </Select>
        </FormControl>
      </Container>


      <Snackbar
        type="success"
        open={saveStateOpen}
        autoHideDuration={2000}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParams()}
        variant="contained"
      ><Translator path="buttonEnviar"/></Button>
    </>
  );
}
