import React from "react";
import { Button, Container, Divider, Title } from "./styles";
import { Link } from 'react-router-dom';

import Translator from "../../components/i18nComponents/Translator";

export default function Strategies() {
  return (

    <Container>

      <Title><Translator path="paginas.Strategies.Title"/></Title>
      <Divider />

      <Button>
        <a href="/config-masaniello"><Translator path="paginas.Strategies.linkMasaniello"/></a>
      </Button>

      <Button>
        <a href="/config-cycles"><Translator path="paginas.Strategies.linkCiclos"/></a>
      </Button>

      <Button>
        <a href="/config-soros"><Translator path="paginas.Strategies.linkSoros"/></a>
      </Button>

      <Button>
        <a href="/config-recovery"><Translator path="paginas.Strategies.linkRecoverPerc"/></a>
      </Button>

      <Button>
        <a href="/config-customcycles"><Translator path="paginas.Strategies.linkCustomCycle"/></a>
      </Button>

    </Container>
  );
}

/*
<Button>
        <a href="/config-customcycles"><Translator path="paginas.Strategies.linkCustomCycle"/></a>
      </Button>

*/