// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translations: {
    // Mesmo valor usado nas configurações (defaultNS)

    // Valores universais
    buttonSalvar: "Salvar",
    snackbarSucess: "Salvo",
    buttonEnviar: "Enviar",
    senha: "Senha",
    buttonSendMetaReq: "Enviar requisição",

    // Componentes
    componentes: {
      MiniDrawer: {
        textUser: "Usuario",
        textRobo: "Robo",

        linksConfig: "Configurações",
        linksComprarRobo: "Comprar Robo",
      },

      // Alert Componente
      AlertsComponente: {
        //exemplo:"texto de exemplo"
      },
      // Fim Alert Componente

      // ChannelCardComponente
      ChannelCardComponente: {
        // Textos soltos no componente
        TextoSalvo: "Salvo",
        ButtonRemover: "Remover",
        textCheckox: "Habilitado",
        accDemoCheckox: "Aceitar demo",
        sendTraderCheckox: "Bot Principal",

        // Texto do Alert no fim do else
        handleSaveElse: "Apenas trader pode remover salas",

        // Textos de label
        TextFieldMultiplicador: "Multiplicador",
        TextFieldEntrada: "Entrada:",
        TextFieldFiltro: "Filtro:",
        TextFieldIgnorar: "Ignorar:",

        // Textos do select SinalTipo
        SelectSinalTipo: {
          // Label
          labelSinalTipo: "sinalTipo",
          // Itens
          MenuItens: {
            MenuItem1: "Agendar",
            MenuItem2: "Listas",
            MenuItem3: "Imediato",
            MenuItem4: "Imediato anterior",
            MenuItem5: "Imediato posterior",
            MenuItem6: "Imediato próxima vela",
            MenuItem7: "Fim da vela",
            MenuItem8: "Minuto corrido",
            MenuItem9: "Intervalo",
          },
        },

        candleColorTipo: {
          // Label
          labelCandleColor: "candleColors",
          // Itens
          MenuItens: {
            MenuItem1: "Desativado",
            MenuItem2: "Retração",
            MenuItem3: "Fluxo",
          },
        },

        botOptions: {
          // Label
          labelCandleColor: "botsID",
          // Itens
          MenuItens: {
            MenuItem1: "Todos os bots",
            MenuItem2: "Retração",
            MenuItem3: "Fluxo",
          },
        },

        // Textos do select Corretora
        SelectCorretora: {
          // Label
          labelCorretora: "Corretora",
          // Itens
          MenuItens: {
            MenuItem1: "Todas",
            MenuItem2: "IQ",
            MenuItem3: "Deriv",
            MenuItem4: "DayProfit",
            MenuItem5: "CapitalBear",
            MenuItem6: "Quotex",
            MenuItem7: "Pocket",
            MenuItem8: "Mango",
            MenuItem9: "Exnova",
            MenuItem10: "Binomo",
            MenuItem11: "Stockity",
            MenuItem12: "Binolla",
            MenuItem13: "Olymp 1",
            MenuItem14: "Olymp 2",
            MenuItem15: "Bullex",
          },
        },
      },
      // Fim ChannelCardComponente -----

      // ReaderParams
      ReaderParams: {
        textFusoHorario: "Fuso horário:",
        textOrdemSimultaneas: "Limite de ordens:",
        textFiltroHorarioInicio: "Filtro horário inicio 1:",
        textFiltroHorarioFinal: "Filtro horário fim 1:",
        textFiltroHorarioInicio2: "Filtro horário inicio 2:",
        textFiltroHorarioFinal2: "Filtro horário fim 2:",
        textFiltroHorarioInicio3: "Filtro horário inicio 3:",
        textFiltroHorarioFinal3: "Filtro horário fim 3:",
        textFiltroHorarioReset: "Horário para reiniciar o bot:",
        textFiltroHorarioZeraPl: "Horário para zerar o placar:",
      },
      // Fim ReaderParams

      // GlobalParamsScreen
      globalParamsScreen: {
        formLabelConta: "Tipo de conta:",
        radioGroupConta: {
          ContaReal: "Conta real",
          ContaDemo: "Conta demo",
        },

        formLabelOpcao: "Tipo de opção",
        radioGroupOpcao: {
          Binarias: "Binarias",
          Digitais: "Digitais",
          Ambas: "Maior Payout",
          BinDig: "Binaria/Digital",
        },

        formLabelEntrada: "Tipo de entrada:",
        radioGroupEntrada: {
          ValorFixo: "Valor fixo $",
          Percentual: "Percentual Banca",
          CopyPerc: "Copiar trader %",
        },

        formLoginMetodo: "Metodo de login",
        formStrategySel: "Gerenciamento",
        formTypeOperSel: "Tipo de operação",

        textEntrada: "Entrada:",
        textGaleNum: "Nivel de Martingale",
        textGale: "Multiplicador",

        textWinStop: "Tipo de Win/Stop:",
        MenuItemWinStop: {
          ValorFixo: "Valor fixo $",
          Percentual: "Percentual Banca",
          Diferencial1: "Diferencial loss",
          Diferencial2: "Diferencial win+loss",
          Quant: "Quantidade",
          Movel: "Móvel",
        },
      },
      // Fim GlobalParamsScreen

      // LossRecoverScreen
      LossRecoverScreen: {
        textCiclos: "Nivel de Ciclos:",
        textFatorCiclos: "Multiplicador do Ciclos:",
        textGaleNum: "Nível de Martingale:",
        textFatorGale: "Multiplicador do Gale",
        textBancaMinima: "Banca Minima:",
        textNumeroHits: "Número de Hits",
        textPosLossEntrada: "PosLoss Entrada:",
        textPosLossFator: "PosLoss Multiplicador:",
        textSoros: "Nivel de Soros:",
        textSorosGale: "MartinGale:",
        textSorosPercentual: "Soros Percentual:",
        textRecoverLoss: "Fator de Recuperação",
        textRecoverProfit: "Lucro p/ Reiniciar",
        textRecoverMode: "Modo",
        textRecoverMode1: "Conservador",
        textRecoverMode2: "Moderado",
        textRecoverMode3: "Agressivo",
      },
      // Fim LossRecoverScreen
    },
    // Fim Componentes -------

    // Páginas
    paginas: {
      // Register
      register: {
        // Alert
        messageErrorDados: "Favor insira os dados corretamente!!",
        messageCadastroOk: "Cadastro ok!",
        messageEmailCadastrado: "Email já cadastrado",
        messageIdCadastrado: "ID já cadastrado",
        messageErroCadastro: "Erro no cadastro",

        // Textos soltos na página
        titlePagina: "Tela de Cadastro",
        subtitlePagina: "Insira os dados de cadastro!!",

        // Label TextField
        textFields: {
          senha: "senha:",
          confirmeSenha: "Confime sua senha:",
          userId: "Id do Telegram",
        },

        // Botão cadastrar
        buttonCadastrar: "Cadastrar",

        // Snackbar
        snackbarCadastroOk: "Cadastro Ok!",

        // Textos da config
        config: {
          email: "O campo email não pode ficar vazio!",
          password: "O campo senha não pode ficar vazio!",
          confirmPassword:
            "O campo senha de confirmação é diferente da digitada",
          confirmPasswordNull:
            "O campo confirmação de senha não pode ficar vazio!",
          userId: "O campo Telegram ID não pode ficar vazio!",
        },
      },
      // Fim register ------------

      // Reader config
      readerConfig: {
        titlePagina: "Parâmetros do Leitor",

        // Restante da página no componente ReaderParams
      },
      // Fim Reader config ------------

      // ProxyConfig
      proxyConfig: {
        Title: "Parâmetros Proxy",
        textProxyendereco: "Proxy endereço:",
        textProxyPorta: "Proxy Porta:",
        textPassword: "Senha:",
      },
      // Fim ProxyConfig

      // NotFound (404)
      notFound: {
        Title: "Pagina não encontrada",
        buttonHome: "Voltar a Home",
      },
      // Fim NotFound

      // MesanielloConfig
      masanielloConfig: {
        messageSaved: "Parametros salvos",

        Title: "Gerenciamento Masaniello",

        textCapital: "Capital:",
        textTotalOperacoes: "Total de Operações:",
        textNumeroVitorias: "Número de Vitórias:",
        textValorAlvo: "Lucro p/ Reiniciar:",
        textValorHit: "Hits p/ Reiniciar:",

        textTipo: "Tipo:",

        MenuItensTipo: {
          Desativado: "Desativado",
          Normal: "Normal",
          Progressive: "Progressivo",
        },

        textModo: "Modo:",

        MenuItensModo: {
          UmSinal: "Um sinal",
          MultiploSinais: "Múltiplos sinais",
        },

        CheckboxReiniciar: "Reiniciar se o capital é perdido",
      },
      // Fim MesanielloConfig

      // GeralConfig
      geralConfig: {
        titleGerais: "Parâmetros Gerais",
        titleRecuperacao: "Parâmetros de Recuperação",

        // Restante da página no componente GlobalParamsScreen
        // Restante da página no componente LossRecoverScreen
      },
      // Fim GeralConfig

      addPhone: {
        Title: "Conectar o telegram",

        textAddTelefone: "Adicionar Telefone:",
        textAddTelegram: "Codigo do Telegram:",
        textSenhaTelegram: "Senha do Telegram:",
      }, //Fim addPhone

      // AuthCode
      authCode: {
        Subtitle: "Insira email e senha",

        labelSenha: "Senha",

        linkRegistro: "Faça o cadastro Aqui",
      }, //Fim authCode

      // BrokerConfig
      BrokerConfig: {
        Title: "Corretoras",
      }, //Fim BrokerConfig

      // BuyRobot
      buyRobot: {
        textTipoDePlano: "Tipo de plano",
        // dias
        dias: {
          plano1: "1 bot 7 dias: R$40",
          plano2: "1 bot 15 dias: R$60",
          plano3: "1 bot 30 dias: R$80",
          plano4: "1 bot 90 dias: R$180",
          plano5: "1 bot 180 dias: R$350",
          plano6: "2 bots 30 dias: R$100",
          plano7: "2 bots 90 dias = R$240",
          plano8: "3 bots 30 dias = R$110",
          plano9: "3 bots 90 dias = R$270",
          plano10: "4 bots 30 dias = R$120",
          plano11: "4 bots 90 dias = R$300",
        },

        Title: "Pagamento",

        textMetodo: "Metodo",
        metodoItens: {
          MercadoPago: "Mercado Pago",
          Hotmart: "Hotmart",
          Pix: "Pix",
        },

        buttonGerarLink: "Gerar Link",

        linkPagamento: "Link de pagamento",

        // label
        labelIdTelegram: "Seu ID do telegram:",
        labelCPF: "CPF (Sem pontos ou traços):",
        labelVendedor: "ID do Vendedor:",

        // Alert
        messageDadosIncorretos: "Favor insira os dados corretamente!",
        messageCpf: "Favor inserir o CPF",
      }, //Fim BuyRobot

      // ChatList
      channelList: {
        messageAtualizando: "Atualizando... aguarde uns segundos",
        messageApenasTrader: "Apenas trader pode atualizar/salvar",

        title: "Grupos e Canais",

        textAguarde: "Aguarde uns segundos!",
        buttonListar: "Listar",
      },
      // Fim ChatList

      // Configure
      configure: {
        Title: "Configuração do Robô",

        linkCorretora: "Corretoras",
        linkGeral: "Configuração Geral",
        linkCopySignals: "Copy Sinais",
        linkCopyTrade: "Copy Trader",
        linkMasaniello: "Masaniello",
        linkProxy: "Configurar Proxy",
        linkComprarRobo: "Comprar o Robo",
        linkSuporte: "Suporte sonnybot",
        linkCopyMetatrader: "Copy Metatrader",
        linkBotsPage: "Bots",
      },
      // Fim Configure

      Botspage: {
        Title: "Bots",

        linkBotcommands: "Comandos do bot",
        linkBotManager: "Gerenciar bots",
      },

      Strategies: {
        Title: "Gerenciamentos",

        linkMasaniello: "Masaniello",
        linkCiclos: "Ciclos",
        linkSoros: "Soros",
        linkRecoverPerc: "Recuperação de loss",
        linkCustomCycle: "Personalizado",
      },

      CustomCycles: {
        subTitle: "Personalizado",
      },

      // ConfigureChannels
      configureChannels: {
        Title: "Configurar Salas",
      },
      // Fim configureChannels

      // CopySignals
      copySignals: {
        Title: "Configuração de Copy Sinais",

        linkAddPhone: "Adicionar Telefone",
        linkChannelList: "Adicionar Salas",
        linkConfigSala: "Configurar Salas",
        linkParametros: "Parâmetros do Leitor",
        linkCopyTrader: "Copiar Salas",

        buttonReset: "Resetar",
        buttonDeletar: "Deletar",
      },
      // Fim CopySignals

      // CopyTrader
      copyTrader: {
        TitleMain: "Gerenciamento de bots",
        TitleTrader: "Configuração Trader",
        TitleSeguidor: "Configuração Seguidor",
        TitleMetaReq: "Seguir um Trader",
        Title: "Configuração Seguidor",

        LoteType: "Tipo de entrada",
        LoteType1: "Entrada fixa",
        LoteType2: "Copy lote",

        TitleMetaCopy: "Configuração MetaCopy",
        TitleMetaTrader: "Configuração Metatrader",
        TitleTraderFollowers: "Lista de Seguidores",

        textHabilitar: "Habilitar copy Trader",
        textListaSeguidores: "Lista de seguidores",

        textSeguidor: "Seguidor",
        buttonRemover: "Remover",
        buttonDesativar: "Desativar",

        textOrdemSimultaneas: "Ordens Simultâneas:",
        textMinValue: "Entrada Minima:",
        textMaxValue: "Entrada Máxima:",
        maturity: "Vencimento",
        typeOfBot: "Tipo do bot",
        actions: "Ações",
      },
      // Fim CopyTrader

      // CopyTraderSignals
      CopyTraderSignals: {
        subTitle: "Configuração Copy trader",
        subTitle2: "Configuração Copiar salas",
        subTitleMetaReq: "Enviar Requisição MetaCopy",
        subTitleCopyReq: "Enviar Requisição CopyTrader",

        textIdTrader: "ID do Trader:",
        textUserName: "Seu Nome(Sem Espaços/Simbols)",
      },

      // Fim CopyTraderSignals
    },
    // Fim Páginas
  },
};
