import React, { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import FormControl from "@mui/material/FormControl";
import Translator from "../../components/i18nComponents/Translator";
import { Checkbox, TextField, Select, MenuItem, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Snackbar from "../../components/Snackbar";

import TraderConfig from "../TraderConfig";
import { SubTitle } from "../../pages/CopyTrader/styles";

import {
  Button,
  InputContainer,
  CardContainer,
  Container,
  SubContainer,
  Divider,
  Title,
} from "./styles";

export default function ConfigureTraderCopy() {

  const { traderInfo, getTraderInfo } = useUser();

  const {t} = useTranslation()

  const [copyTraderEn, setTraderEn] = useState(null);
  //const [maxTrades, setMaxTrades] = useState(null);
  const [signalType, setSignalType] = useState(null);
  const [delaySig, setDelaySig] = useState(null);
  const [stakeMin, setStakemin] = useState(null);
  const [brokerID, setBrokerID] = useState(null);
  const [candleColor, setCandleColor] = useState(null);
  const [paresFilter, setParesFilter] = useState(null);
  const [timeframe, setTimeFrame] = useState(null);

  const { sendTraderInfo } = useUser();
  const [saveStateOpen, setSaveStateOpen] = useState(false);

  const handleSave = async () => {
      //
      if (delaySig.indexOf(",") == -1 && signalType == 9) {
          alert("Parametro invalido em Delay");
          return;
      }
      //
      if (paresFilter.indexOf(" ") != -1) {
          alert("Não use espaços em Ativos");
          return;
      }
      //
      if ((candleColor == 1 || candleColor == 2) && !paresFilter) {
          alert("Ativos não definido");
          return;
      }

      await sendTraderInfo({
        copyTraderEn: copyTraderEn,
        //maxTrades: maxTrades,
        signalType: signalType,
        delaysig: delaySig,
        brokerID: brokerID,
        candleColor: candleColor,
        timeframe: timeframe,
        paresFilter: paresFilter,
        stakeMin: stakeMin,
      });
      //
      setSaveStateOpen((prevState) => !prevState);
     
  };


  const handleBrokerID = (event) => {
    setBrokerID(event.target.value);
  };

  const handleCandleColor = (event) => {
    setCandleColor(event.target.value);
  };

  useEffect(() => {
    getTraderInfo();
  }, []);

  useEffect(() => {
    if (traderInfo) {

        setTraderEn(traderInfo.copyTraderEn);
        //setMaxTrades(traderInfo.maxTrades);
        setSignalType(traderInfo.signalType);
        setDelaySig(traderInfo.delaysig);
        setBrokerID(traderInfo.brokerID);
        setCandleColor(traderInfo.candleColor);
        setTimeFrame(traderInfo.timeframe);
        setParesFilter(traderInfo.paresFilter);
        setStakemin(traderInfo.stakeMin);
        //
    }
  }, [traderInfo]);

  return (
    <>

      <Title>Configuração Trader</Title>

      <Divider />

      <Container>
      <InputContainer>
        <Checkbox checked={copyTraderEn} onChange={() => setTraderEn(!copyTraderEn)} />
        <div>{t("componentes.ChannelCardComponente.textCheckox")}</div>
      </InputContainer>
      </Container>

      <Divider />


      <Container>

      <SubContainer>
        <div>Delay</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={delaySig}
            type="text"
            onChange={(e) => {
              setDelaySig(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div>Stake minimo</div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={stakeMin}
            type="text"
            onChange={(e) => {
              setStakemin(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.labelSinalTipo"/></SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={signalType}
            defaultValue={7}
            onChange={(e) => setSignalType(e.target.value)}
            required
          >
            <MenuItem value={4}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem4"/></MenuItem>
            <MenuItem value={5}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem5"/></MenuItem>
            <MenuItem value={6}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem6"/></MenuItem>
            <MenuItem value={7}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem7"/></MenuItem>
            <MenuItem value={9}><Translator path="componentes.ChannelCardComponente.SelectSinalTipo.MenuItens.MenuItem9"/></MenuItem>
          </Select>
        </FormControl>
      </Container>

      <Divider />


      <Container>
      <SubTitle>Corretora</SubTitle>
      <Select
          labelId="demo-select-small"
          value={brokerID}
          label={t('componentes.ChannelCardComponente.SelectCorretora.labelCorretora')}
          defaultValue={0}
          onChange={handleBrokerID}
          required
        >
          <MenuItem value={0}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem1"/></MenuItem>
          <MenuItem value={1}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem2"/></MenuItem>
          <MenuItem value={2}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem3"/></MenuItem>
          <MenuItem value={3}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem4"/></MenuItem>
          <MenuItem value={4}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem5"/></MenuItem>
          <MenuItem value={5}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem6"/></MenuItem>
          <MenuItem value={6}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem7"/></MenuItem>
          <MenuItem value={7}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem8"/></MenuItem>
          <MenuItem value={8}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem9"/></MenuItem>
          <MenuItem value={9}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem10"/></MenuItem>
          <MenuItem value={10}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem11"/></MenuItem>
          <MenuItem value={11}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem12"/></MenuItem>
          <MenuItem value={12}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem13"/></MenuItem>
          <MenuItem value={13}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem14"/></MenuItem>
          <MenuItem value={14}><Translator path="componentes.ChannelCardComponente.SelectCorretora.MenuItens.MenuItem15"/></MenuItem>
        </Select>
        </Container>

        <Divider />


      <Container>
      <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >

      <SubTitle>Filtro cor de vela</SubTitle>
      <Select
          labelId="demo-select-small"
          value={candleColor}
          label={t('componentes.ChannelCardComponente.candleColorTipo.labelSinalTipo')}
          defaultValue={0}
          onChange={handleCandleColor}
          required
        >
          <MenuItem value={0}><Translator path="componentes.ChannelCardComponente.candleColorTipo.MenuItens.MenuItem1"/></MenuItem>
          <MenuItem value={1}><Translator path="componentes.ChannelCardComponente.candleColorTipo.MenuItens.MenuItem2"/></MenuItem>
          <MenuItem value={2}><Translator path="componentes.ChannelCardComponente.candleColorTipo.MenuItens.MenuItem3"/></MenuItem>
        </Select>


        <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={timeframe}
            label="Timeframe"
            type="number"
            onChange={(e) => {
              setTimeFrame(e.target.value);
            }}
            variant="filled"
          />

          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={paresFilter}
            label="Ativos"
            type="text"
            onChange={(e) => {
              setParesFilter(e.target.value);
            }}
            variant="filled"
          />
        </Box>

        </Container>


        <Snackbar
        type="success"
        open={saveStateOpen}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="componentes.ChannelCardComponente.TextoSalvo"/>
      </Snackbar>


      <Button onClick={() => handleSave()}><Translator path="buttonSalvar"/></Button>

    </>
  );
}
