import React, { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, TextField, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import { SubContainer, Divider } from "./styles";
import Snackbar from "../../components/Snackbar";
import { useUser } from "../../contexts/UserContext";
import { SubTitle } from "../../pages/CopyTrader/styles";
import Translator from "../../components/i18nComponents/Translator";

export default function ConfigureLossRecover() {

  const { sendLossRecParams, getLossRecParams, lossRecParams, userId } = useUser();

  const [recoverPerc, setRecPerc] = useState(null);
  const [perc2Server, setPerc2Server] = useState(null);
  const [recoverGale, setRecoverGale] = useState(null);
  const [recoverGaleFat, setRecGaleFat] = useState(null);
  const [recoverProfit, setRecProfit] = useState(null);
  const [recoverMode, setRecMode] = useState(null);
  const [brokerID, setBrokerID] = useState(null);
  const [saveStateOpen, setSaveStateOpen] = useState(false);
  //

  const handleGetPerc = (perc) => {

      if (perc === 50) {
          setRecPerc(1);

      } else if (perc === 40) {
          setRecPerc(2);

      } else if (perc === 25) {
          setRecPerc(3);

      } else if (perc === 20) {
          setRecPerc(4);

      } else if (perc === 15) {
          setRecPerc(5);

      } else if (perc === 10) {
          setRecPerc(6);

      } else if (perc === 5) {
          setRecPerc(7);

      } else {
          setRecPerc(0);
      }
      setPerc2Server(perc);
  };

  const handleSetPerc = (event) => {

      var perc = event.target.value;
      setRecPerc(perc);

      if (perc === 1) {
          setPerc2Server(50);

      } else if (perc === 2) {
          setPerc2Server(40);

      } else if (perc === 3) {
          setPerc2Server(25);

      } else if (perc === 4) {
          setPerc2Server(20);

      } else if (perc === 5) {
          setPerc2Server(15);

      } else if (perc === 6) {
          setPerc2Server(10);

      } else if (perc === 7) {
          setPerc2Server(5);

      } else {
          setPerc2Server(0);
      }
  };

  const handleSaveParamsRecv = useCallback(async () => {
    //
    
    //
    //
    setSaveStateOpen((prevState) => !prevState);
    //
    await sendLossRecParams({
      recoverPerc: perc2Server,
      recoverGale,
      recoverGaleFat,
      recoverProfit,
      recoverMode,
      brokerID,
    });
  }, [
    sendLossRecParams,
      recoverPerc,
      recoverGale,
      recoverGaleFat,
      recoverProfit,
      recoverMode,
      brokerID,
  ]);

  const handleSetRecoveryParams = (data) => {
    //setRecPerc(data.recoverPerc);
    handleGetPerc(data.recoverPerc);
    setRecoverGale(data.recoverGale);
    setRecGaleFat(data.recoverGaleFat);
    setRecProfit(data.recoverProfit);
    setRecMode(data.mode);
  };

  useEffect(() => {
    if (lossRecParams)  {
      handleSetRecoveryParams(lossRecParams);
    }
  }, [lossRecParams]);

  useEffect(() => {
    if (userId) {
      var brokerIDX = localStorage.getItem("brokerID");
      if (brokerIDX === null) {
        brokerIDX = 1;
      }
      setBrokerID(brokerIDX);
      getLossRecParams(brokerIDX);
    }
  }, [userId]);


  return (
    <>

      <SubContainer>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle><Translator path="componentes.LossRecoverScreen.textRecoverLoss"/></SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={recoverPerc}
            defaultValue={0}
            onChange={handleSetPerc}
            required
          >
            <MenuItem value={0}><Translator path="paginas.masanielloConfig.MenuItensTipo.Desativado"/></MenuItem>
            <MenuItem value={1}>50%</MenuItem>
            <MenuItem value={2}>40%</MenuItem>
            <MenuItem value={3}>25%</MenuItem>
            <MenuItem value={4}>20%</MenuItem>
            <MenuItem value={5}>15%</MenuItem>
            <MenuItem value={6}>10%</MenuItem>
            <MenuItem value={7}>5%</MenuItem>
          </Select>
        </FormControl>
      </SubContainer>

      <SubContainer>
        <FormControl sx={{ minWidth: "30%" }} size="small">
          <SubTitle><Translator path="componentes.LossRecoverScreen.textRecoverMode"/></SubTitle>
          <Select
            sx={{ width: "275px" }}
            value={recoverMode}
            defaultValue={1}
            onChange={(e) => setRecMode(e.target.value)}
            required
          >
            <MenuItem value={1}><Translator path="componentes.LossRecoverScreen.textRecoverMode1"/></MenuItem>
            <MenuItem value={2}><Translator path="componentes.LossRecoverScreen.textRecoverMode2"/></MenuItem>
            <MenuItem value={3}><Translator path="componentes.LossRecoverScreen.textRecoverMode3"/></MenuItem>
          </Select>
        </FormControl>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textGaleNum"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={recoverGale}
            type="number"
            onChange={(e) => {
              setRecoverGale(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textFatorGale"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={recoverGaleFat}
            type="number"
            onChange={(e) => {
              setRecGaleFat(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <SubContainer>
        <div><Translator path="componentes.LossRecoverScreen.textRecoverProfit"/></div>
        <Box
          component="form"
          sx={{
            maxWidth: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            sx={{ marginBottom: "15px" }}
            value={recoverProfit}
            type="number"
            onChange={(e) => {
              setRecProfit(e.target.value);
            }}
            variant="filled"
          />
        </Box>
      </SubContainer>

      <Divider />

      <Snackbar
        type="success"
        open={saveStateOpen}
        autoHideDuration={2000}
        onClose={() => setSaveStateOpen(false)}
      >
        <Translator path="snackbarSucess"/>
      </Snackbar>

      <Button
        sx={{ width: "270px" }}
        onClick={() => handleSaveParamsRecv()}
        variant="contained"
      ><Translator path="buttonSalvar"/></Button>

    </>
  );
}
