import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";

import {
  AppContainer,
  SubTitle,
  Table,
  TableHeader,
  Input,
  Button,
  TableRow,
  TableCol,
  TableHeaderTr,
  TableContainer,
} from "./styles";
import Translator from "../../components/i18nComponents/Translator";

const base_url = process.env.REACT_APP_BASE_URL;

const TDColumn = ({ value, onChange }) => (
  <TableCol>
    <Input type="text" value={value} onChange={onChange} />
  </TableCol>
);

const CustomCycles = () => {
  const initialRows = Array.from({ length: 10 }, (_, index) => ({
    cycle: index + 1,
    entrada: "",
    mg1: "",
    mg2: "",
    mg3: "",
    mg4: "",
    mg5: "",
    mg6: "",
    mg7: "",
  }));

  const [rows, setRows] = useState(initialRows);
  const [inputs, setInputs] = useState(initialRows);
  const [loading, setLoading] = useState(false);

  const { userId, handleSendCustomCycles } = useUser();

  const handleInputChange = (e, rowIndex, fieldName) => {
    const newValues = [...inputs];
    newValues[rowIndex][fieldName] = e.target.value;
    setInputs(newValues);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${base_url}/getcustcycle?userid=${userId}`)
      .then((response) => response.json())
      .then((data) => {
        const updatedRows = [...initialRows];

        data.forEach((item, index) => {
          if (updatedRows[index]) {
            updatedRows[index] = {
              ...updatedRows[index],
              entrada: item.entrada,
              mg1: item.mg1,
              mg2: item.mg2,
              mg3: item.mg3,
              mg4: item.mg4,
              mg5: item.mg5,
              mg6: item.mg6,
              mg7: item.mg7,
            };
          }
        });

        setInputs(updatedRows);
        setRows(updatedRows);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setLoading(false);
      });
  }, [userId]);

  const handleSave = () => {
    const seenCycles = new Set();

    const data = inputs
      .map((row, rowIndex) => ({
        cycle: row.cycle,
        entrada: row.entrada,
        mg1: row.mg1,
        mg2: row.mg2,
        mg3: row.mg3,
        mg4: row.mg4,
        mg5: row.mg5,
        mg6: row.mg6,
        mg7: row.mg7,
      }))
      .filter((row) => {
        if (seenCycles.has(row.cycle)) {
          return false;
        }
        seenCycles.add(row.cycle);
        return true;
      });

    if (data.length === 0) {
      console.log("Nenhum ciclo válido para enviar.");
      return;
    }

    handleSendCustomCycles(data, setLoading);
  };

  return (
    <AppContainer>
      <SubTitle>
        <Translator path="paginas.CustomCycles.subTitle" />
      </SubTitle>
      <TableContainer>
        <Table>
          <thead>
            <TableHeaderTr>
              <TableHeader>MG 0</TableHeader>
              <TableHeader>MG 1</TableHeader>
              <TableHeader>MG 2</TableHeader>
              <TableHeader>MG 3</TableHeader>
              <TableHeader>MG 4</TableHeader>
              <TableHeader>MG 5</TableHeader>
              <TableHeader>MG 6</TableHeader>
              <TableHeader>MG 7</TableHeader>
            </TableHeaderTr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TDColumn
                  value={inputs[rowIndex].entrada}
                  onChange={(e) => handleInputChange(e, rowIndex, "entrada")}
                />
                <TDColumn
                  value={inputs[rowIndex].mg1}
                  onChange={(e) => handleInputChange(e, rowIndex, "mg1")}
                />
                <TDColumn
                  value={inputs[rowIndex].mg2}
                  onChange={(e) => handleInputChange(e, rowIndex, "mg2")}
                />
                <TDColumn
                  value={inputs[rowIndex].mg3}
                  onChange={(e) => handleInputChange(e, rowIndex, "mg3")}
                />
                <TDColumn
                  value={inputs[rowIndex].mg4}
                  onChange={(e) => handleInputChange(e, rowIndex, "mg4")}
                />
                <TDColumn
                  value={inputs[rowIndex].mg5}
                  onChange={(e) => handleInputChange(e, rowIndex, "mg5")}
                />
                <TDColumn
                  value={inputs[rowIndex].mg6}
                  onChange={(e) => handleInputChange(e, rowIndex, "mg6")}
                />
                <TDColumn
                  value={inputs[rowIndex].mg7}
                  onChange={(e) => handleInputChange(e, rowIndex, "mg7")}
                />
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <Button onClick={handleSave} disabled={loading}>
        {loading ? "Salvando os dados..." : "Salvar"}
      </Button>
    </AppContainer>
  );
};

export default CustomCycles;
