import axios from "axios";
import { register } from "./user/register";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
});

export { register, api };
