import React, { useEffect, useState } from "react";
import {
  Checkbox,
} from "@mui/material";

import {
  Container,
  Title,
  Divider,
  InputContainer,
  FollowerContainer,
  ButtonContainer,
  TextName,
  TextContainer,
  GridFollowContainer,
  SubTitle,
} from "./styles";

import { Box } from "@mui/system";
import { useUser } from "../../contexts/UserContext";

import Translator from "../../components/i18nComponents/Translator";
import { useTranslation } from "react-i18next";

export default function FollowersCopy() {
  const {
    handleFollowers,
    seguidores,
    handleRemoveFollow,
  } = useUser()

  const [checkedTrader, setCheckedTrader] = useState(false);
  const [checkedFollow, setCheckedFollow] = useState(false);

  const {t} = useTranslation()

  // Funções relacionadas aos seguidores
  const handleCheckFollow = () => {
    setCheckedFollow(!checkedFollow);
    // adicionar função de envio dessa informação
  };

  const handleRemoveFollowExec = (id) => {
    // adicionar função de remover seguidor
    handleRemoveFollow(id);
    //
    console.log('remove ', id);
    window.location.reload();
  };

  const handleDisableFollow = (id) => {
    // adicionar função de desabilitar seguidor
    console.log("Desabilitar seguidor", id);
  };

  const handleCheckTrader = () => {
    setCheckedTrader(!checkedTrader);

    // adicionar função de envio dessa informação
  };

  useEffect(() => {
    handleFollowers();
  }, []);

  return (
    <Container>
      
      <Title>Lista de seguidores</Title>
          <Divider />

          <GridFollowContainer>
            {seguidores.length > 0 && (
              <>
                {seguidores.map((seguidor) => (
                  <FollowerContainer>
                    <TextContainer>
                      <TextName className="follower">
                        {seguidor.name}
                      </TextName>
                      <TextName className="follower">ID: {seguidor.id}</TextName>
                    </TextContainer>

                    <ButtonContainer>
                      <div
                        className="removeBtn"
                        onClick={() => handleRemoveFollowExec(seguidor.id)}
                      >
                        Remover
                      </div>

                      <div
                        className="desableBtn"
                        onClick={() => handleDisableFollow(seguidor.id)}
                      >
                        Info
                      </div>
                    </ButtonContainer>
                  </FollowerContainer>
                ))}
              </>
            )}
          </GridFollowContainer>

      </Container>
  );
}
